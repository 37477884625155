export const esMessages = {
  custom: {
    language: "Idioma",
    profile: "Ir al perfil",
    changeAccount: "Cambio de cuenta",
    logout: "Cerrar sesión",
    accountSetup: "Configuración de cuenta",
    uploadData: "Cargar datos de cuenta",
    step1: "Paso 1: Descargue y complete la plantilla de carga de la cuenta",
    step2: "Paso 2: Cargar el archivo de la plantilla de la cuenta",
    seeUploadTemplate: "Consulte la plantilla para obtener más información",
    downloadTemplate: "Descargue la plantilla",
    errors: "Errores",
    downloadData: "Descargar datos de cuenta",
    inventory: "Inventario",
    schedules: "Horarios",
    locations: "Ubicaciones",
    greaseTags: "Etiquetas de lubricación",
    routeEditor: "Editor de rutas",
    devices: "Dispositivos",
    assets: "Activos",
    events: "Eventos",
    compliance: "Cumplimiento",
    administration: "Administración",
    manageUsers: "Administración de usuarios",
    manageDevices: "Administración de dispositivos",
    manageAccounts: "Administración de cuentas",
    supportCentre: "Centro de ayuda",
    cancel: "Cancelar",
    confirm: "Confirmar",
    confirmLeave: "Está seguro que desea salir de esta página?",
    unsavedChanges:
      "Todavía podrían haber cambios no guardados en esta página, si pulsa confirmar será navegado fuera y todos los cambios no guardados se perderán.",
    unsavedChangesShort: "Todos los cambios no guardados se perderán",
    howTo: "Videos guía explicativos",
    howToView: "Vea las últimas guías de videos instructivos",
    howToBtn: "Videos instructivos",
    password: "contraseña",
    supportDocumentation: "Documentación de apoyo",
    supportDocumentationView:
      "Vea nuestra última documentación de apoyo en línea.",
    supportDocumentatioBtn: "Vea la documentación de apoyo",
    support: "Apoyo",
    supportText:
      "Póngase en contacto con nuestro equipo de asistencia y obtenga su orientación sobre problemas o preguntas relacionadas con sus productos.",
    supportRequest: "Solicite ayuda al equipo de asistencia",
    supportEmail: "Envíenos un correo electrónico",
    greaseTypes: "Tipos de lubricación",
    greaseTypesText:
      "Registre los diferentes tipos de lubricaciónes requeridos por la maquinaria dentro de su cuenta.",
    schedulesText:
      "Los programas definen la frecuencia y fechas en las cuales las etiquetas en su cuenta requieren lubricación.",
    locationsText:
      "Diferentes zonas dentro de su cuenta que contienen activos y puntos para engrasar.",
    assetsText:
      "Los activos son partes de la planta dentro de su cuenta que están equipadas con etiquetas de engrase.",
    greaseTagsText:
      "Los puntos de lubricación asociados con los activos dentro su cuenta, y relacionados con las etiquetas de puntos de lubricación Greaseboss.",
    routeEditorText:
      "Representa el orden en el cual los puntos de lubricación se deberían engrasar",
    devicesText:
      "Representa las Head Units de Greaseboss registradas dentro de su cuenta.",
    name: "Nombre",
    description: "Descripción",
    manufacturer: "Fabricante",
    consumables: "Consumibles",
    actions: "Acciones",
    add: "Agregar",
    exit: "Salir",
    edit: "Editar",
    delete: "Eliminar",
    discardChanges: "Eliminar cambios",
    save: "Guardar",
    saveChanges: "Guardar los cambios?",
    saveChangesConfirmation:
      "Está seguro que quiere guardar los cambios realizados en los ítems seleccionados?",
    continue: "Continuar",
    deleteItems: "Eliminar ítems?",
    deleteItemsConfirmation:
      "Está seguro que quiere eliminar los ítems seleccionados?",
    exportCSV: "Exportar como CSV",
    search: "Buscar…",
    frequency: "Frecuencia",
    recurrence: "Recurrencia",
    timezone: "Zona horaria",
    startDate: "Fecha de inicio",
    summary: "Resumen",
    addSchedule: "Agregar programa",
    mustSelectDay:
      "Se debe seleccionar un día para una semana para una programa semanal o mensual.",
    error: "Error ",
    scheduleSuccessfully: "El programa fue exitosamente",
    updated: "Actualizado",
    created: "Creado",
    somethingWentWrong: "Algo no salió bien al crear el programa",
    dailyRecurrenceInterval: "Intervalo de Recurrencia Diaria",
    recurranceXDays: "Recurrencia cada X Días",
    weeklyRecurrenceInterval: "Recurrencia Semanal",
    recurranceXWeeks: "Recurrencia cada X Semanas",
    recurranceXMonths: "Recurrencia cada X Meses",
    recurrenceInterval: "Intervalo de Recurrencia   ",
    mustSelectWeek: "Se debe seleccionar al menos un día en la semana",
    monthlyRecurrence: "Recurrencia Mensual",
    mustSelectMonth: "Se debe seleccionar al menos un día para el mes",
    updateSchedule: "Actualización del programa",
    createSchedule: "Creación de programa",
    scheduleName: "Nombre del programa",
    daily: "Diario",
    weekly: "Semanal",
    monthly: "Mensual",
    endDate: "Fecha de finalización",
    parentLocation: "Ubicación de la matriz",
    descriptionNotes: "Descripción/Notas",
    model: "Modelo",
    parentAsset: "Activo de la matriz",
    numTags: "Cantidad de etiquetas",
    location: "Ubicación ",
    order: "Orden",
    asset: "Activo",
    consumable: "Consumible ",
    volume: "Volumen (cc)",
    rfid: "RFID",
    schedule: "Programa ",
    tagName: "Nombre de la Etiqueta",
    grease: "Lubricante",
    modelNumber: "Número de Modelo",
    serialNumber: "Número de Serie",
    firmwareVersion: "Versión Firmware",
    occurred: "Ocurrido",
    point: "Punto",
    volumeDispensed: "Volumen aplicado (cc)",
    eventsText:
      "Visualización de eventos publicados por dispositivos dentro su red greasecloud",
    showEventsFrom: "Mostrar todos los eventos desde",
    until: "Hasta",
    email: "Correo electrónico",
    currentRole: "Rol actual",
    confirmed: "Confirmado",
    currentAccount: "Cuenta actual",
    user: "Usuario",
    updateRoles: "Actualización de roles",
    resendInvite: "Reenviar la invitación",
    manageUsersText:
      "Conceder acceso a usuarios nuevos, revocar el acceso y asignar roles de usuarios",
    id: "Identificación",
    account: "Cuenta ",
    device: "Dispositivo ",
    manageDevicesText:
      "Crear identidades para dispositivos nuevos, actualizar sus cuentas y monitorear el uso",
    manageAccountsText:
      "Agregar, eliminar y actualizar cuentas con acceso al sistema GreaseCloud",
    deviceCreation: "Creación exitosa del Dispositivo",
    deviceCreationText1:
      "El dispositivo se creó exitosamente con las siguientes credenciales, agradeceremos revisar los siguientes detalles y guardarlos en un lugar seguro",
    deviceCreationText2:
      "No podrá visualizar estos detalles nuevamente después de cerrar la ventana",
    secretKey: "contraseña SECRETA",
    close: "Cierre",
    roleRemoved: "El rol de usuario se eliminó exitosamente",
    roleRemovedError:
      "Algo salió mal cuando se intentó eliminar el rol de usuario",
    roleAdded: "El rol de usuario se agregó exitosamente",
    roleAddedError:
      "Algo salió mal cuanto se intentó agregar el rol de usuario",
    updateUserRoles: "Actualización de Roles de Usuario",
    role: "Rol  ",
    remove: "Eliminación",
    userInvitation: "Se envió exitosamente la invitación al usuario",
    userInvitationError: "Error en el envío de la invitación al usuario",
    userReinvitation: "Se invitó nuevamente con éxito el usuario a la cuenta",
    userReinvitationError: "La nueva invitación del usuario a la cuenta falló",
    success: "Éxito ",
    userDeleted: "Se eliminó exitosamente el usuario de la cuenta",
    userDeletedError:
      "Ocurrió un error cuando se intentó eliminar el usuario de la cuenta.  Podrían faltar los permisos requeridos",
    accountCreated: "Se creó la cuenta exitosamente",
    accountCreatedError:
      "Ocurrió un error cuando se intentó crear una cuenta nueva",
    consumableCreated: "La cuenta se creó exitosamente",
    consumableCreatedError: "Algo salió mal cuando se creó el consumible",
    consumableDeleted:
      "Los consumibles seleccionados se eliminaron exitosamente",
    consumableDeletedError:
      "Ocurrió un error cuando se intentó eliminar los consumibles seleccionados",
    consumableUpdated: "Se actualizó exitosamente el consumible seleccionado",
    consumableUpdatedError:
      "El consumible modificado no se actualizó exitosamente",
    cosnumableIncorrectInput:
      "Los siguientes consumibles fallaron debido a entradas incorrectas",
    deviceUpdated: "El dispositivo seleccionado se actualizó exitosamente",
    deviceUpdatedError:
      "El dispositivo modificado no se actualizó exitosamente",
    assetsDeleted: "Los activos seleccionados se eliminaron exitosamente",
    deviceIncorrectInput:
      "Los siguientes activos fallaron debido a entradas incorrectas",
    assetsDeletedError:
      "Ocurrió un error cuando se intentó eliminar los activos seleccionados",
    assetsUpdated: "Los activos seleccionados se actualizaron exitosamente",
    assetsUpdatedError: "El activo modificado no se actualizó exitosamente",
    assetsCreatedError: "Algo salió mal cuando se intentó crear el activo",
    assetsIncorrectInput:
      "Los siguientes activos fallaron debido a entradas incorrectas",
    pointsCreatedError: "Algo salió mal cuando se intentó crear el punto.",
    pointsDeleted: "Los puntos seleccionados fueron eliminados exitosamente",
    pointsDeletedError:
      "Ocurrió un error cuando se intentó eliminar los puntos seleccionados",
    pointsUpdated: "Los puntos seleccionados se actualizaron exitosamente",
    pointsUpdatedError: "El punto modificados no se actualizó exitosamente",
    routeUpdated: "Se actualizó exitosamente el orden de la ruta",
    pointsIncorrectInput:
      "Los siguientes puntos fallaron debido a entradas incorrectas",
    routeUpdatedError: "No fue posible actualizar el orden de la ruta",
    schedulesDeleted: "Los programas seleccionados se eliminaron exitosamente",
    schedulesDeletedError:
      "Ocurrió un error cuando se intentó eliminar los programas seleccionados",
    errorOccured: "Ocurrió un error",
    errorPermissions:
      "El servidor devolvió un error indicando que no tiene los permisos requeridos para acceder a los recursos de esta página.",
    deleteItemLabel: "Está seguro que quiere eliminar los seleccionados?",
    selectOption: "Opción de selección",
    occurringAfter: "Ocurre después…",
    notFound: "No encontrado",
    fieldIsRequired: "Se requiere un campo",
    volumeRequirementValidation:
      "El valor debe ser -1, mayor o igual a 0 o ajustado a 'Purga' o 'P'. Se debe especificar el número después del decimal",
    createNewAccount: "Creación de una Cuenta Nueva",
    addAccount: "Agregar una cuenta nueva a su cuenta",
    createAccount: "Crear una Cuenta",
    accountName: "Nombre de la Cuenta",
    addUser: "Agregar Usuario",
    addNewUser: "Agregar un usuario nuevo a su cuenta",
    siteId: "Identificación de la cuenta",
    roleId: "Identificación del rol",
    userId: "Identificación del usuario",
    inviteUser: "Invitar un Usuario",
    currentFeed: "Su alimentación actual",
    selectAccountId:
      "Se debe seleccionar la identificación de una cuenta para un dispositivo",
    emailRequired: "Se requiere un correo electrónico",
    invalidEmail: "Correo electrónico inválido",
    roleRequired: "Se requiere un Rol",
    noSpecialCharacters: "No debe contener ningún caracter especial",
    locationCreatedError: "Algo salió mal cuando se creó la ubicación",
    locationDeleted: "Las ubicaciones seleccionadas se eliminaron exitosamente",
    locationDeletedError:
      "Ocurrió un error cuando se intentó eliminar las ubicaciones seleccionadas",
    locationUpdated:
      "Todas las ubicaciones modificadas se actualizaron exitosamente",
    locationUpdatedError:
      "La ubicación modificada no se actualizó exitosamente",
    invalidAction: "Acción inválida",
    locationIncorrectInput:
      "Las siguientes ubicaciones fallaron debido a entradas incorrectas",
    invalidStatusResult: "Estatus de resultado inválido",
    noRedirect: "No se pudo redireccionar",
    invalidAuthentication: "Estatus de resultado de autenticación inválida",
    deviceCreated: "El dispositivo se creó exitosamente   ",
    deviceCreatedError: "Algo salió mal cuanto se creó el dispositivo",
    deviceDeleted: "Los dispositivos especificados se eliminaron exitosamente",
    deviceDeletedError: "Algo salió mal cuando se eliminaron los dispositivos",
    sunday: "D",
    monday: "L",
    tuesday: "M",
    wednesday: "M",
    thursday: "J",
    friday: "V",
    saturday: "S",
    true: "Verdadero",
    false: "Falso",
    superAdmin: "SuperAdmin",
    accountAdmin: "AccountAdmin",
    manager: "Administrador",
    generalUser: "Usuario General",
    onboardApp: "Aplicación Onboard",
    goToLink: "Enlace abierto",
    copyLink: "Copiar enlace dirección",
    reports: "Informes",
    complianceReport: "Informe de Cumplimiento",
    selectAll: "Seleccionar Todo",
    points: "Puntos",
    generateReport: "Generar Informe",
    clearFilters: "Borrar Filtros",
    selected: "Seleccionados",
    loading: "Cargando...",
    pendingActions: "Informe de Acciones Pendientes",
    scheduleEmail: "Programar Correo Electrónico",
    emailScheduleText:
      "Programar la entrega de correo electrónico para este informe.",
    from: "De",
    to: "Para",
    subject: "Asunto",
    period: "Período",
    time: "Hora",
    endpointReport: "Informe de Endpoints",
    setFiltersText: "Configure los filtros para generar el informe.",
    endpoints: "Endpoints",
    uploaded: "Subido",
    detailTreeList: "Lista de Detalles",
    detailView: "Vista Detallada",
    manualSystem: "Sistema Manual",
    automaticSystem: "Sistema Automático",
    site: "Sitio",
    expectedConsumable: "Consumible Esperado",
    volumeDelivered: "Vol. Entregado",
    dueDate: "Fecha de Vencimiento",
    expiryDate: "Fecha de Caducidad",
    compliant: "Realizado",
    volumeRequirement: "Vol. Requerido",
    volumeTolerance: "Vol. Tolerado",
    status: "Status",
    viewActionsText: "Vea Acciones en su GreaseCloud network",
    showAllActionsFrom: "Acciones desde",
    pending: "Pendiente",
    closed: "Finalizado",
    endpointCreated: "El punto final se creó con éxito",
    endpointCreatedError: "Algo salió mal al crear el punto final",
    endpointDeleted: "Los puntos finales especificados se eliminaron con éxito",
    endpointDeletedError: "Algo salió mal al eliminar los puntos finales",
    endpointUpdated: "El punto final seleccionado se actualizó correctamente.",
    endpointUpdatedError:
      "El punto final modificado no se actualizó correctamente.",
    endpointIncorrectInput:
      "Los siguientes puntos finales fallaron debido a una entrada incorrecta:",
    manageEndpoints: "Administrar Puntos Finales",
    manageendpointsText:
      "Cree identidades para nuevos puntos finales, actualice sus cuentas y supervise el uso.",
    endpointsText:
      "Representa los puntos finales de Greaseboss registrados en su cuenta.",
    hardwareVersion: "Versión del Hardware",
    schedulePattern: "Patrón de horario",
    notes: "Notas",
    temperatureInC: "Temperatura (°C)",
    temperature: "Temperatura",
    voltage: "Voltaje(V)",
    rssiDbm: "RSSI (dBm)",
    endpointRssi: "Punto Final RSSI (dBm)",
    cumulative: "Acumulativa (cc)",
    list: "Lista",
    noSchedule: "Sin horario",
    pointActivity: "Actividad de punto",
    endpointActivity: "Actividad de punto final",
    activity: "Actividad",
    metrics: "Métrica",
    selectPointEndpoint: "Seleccione un activo o un punto/punto final.",
    noDataFound: "Datos no encontrados",
    changeDateRangeAndPoint:
      "Cambie el rango de fechas o seleccione otro punto/punto final.",
    diagnostics: "Diagnóstico",
    thisCouldBeBecause: "Esto podría deberse a que",
    trySelectingDates:
      "El punto final no ha publicado diagnósticos por un tiempo. Intente seleccionar fechas diferentes.",
    unableTocommunicate:
      "El punto final no puede comunicarse con GreaseCloud. Póngase en contacto con el soporte.",
    siteExplorer: "Explorar Sitios",
    endpointMapping: "Mapeo de Punto Final",
    tagMapping: "Mapeo de Etiqueta",
    sessionExpired: "Su sesión ha caducado, ahora se cerrará la sesión.",
    none: "Ninguno",
    aggregator: "Agregador",
    complianceSent: "Informe de cumplimiento enviado con éxito.",
    unableToSendEmail: "No se puede enviar el informe por correo electrónico.",
    sendEmail: "Enviar correo electrónico",
    send: "Enviar",
    sendEmailText: "Enviar correo electrónico para este informe.",
    reportDate: "Fecha del informe",
    processing: "Procesando...",
    last: "Últimos",
    days: "Días",
    today: "Hoy",
    yesterday: "Ayer",
    reservoirs: "Depósitos",
    reservoirSuccessfully: "depósitos ha sido exitosamente",
    reservoirDeleted: "El depósitos seleccionado se eliminó con éxito.",
    reservoirDeletedError:
      "Ocurrió un error al intentar eliminar el depósitos seleccionado.",
    addReservoir: "Agregar depósitos",
    updateReservoir: "Actualizar depósitos",
    deleteReservoir: "Eliminar depósitos",
    linkedPoints: "Puntos finales vinculados",
    addNote: "Agregar o editar una nota:",
    reservoirText:
      "Los depósitoss se pueden utilizar para controlar el nivel de volumen de los autolubricadores de grasa, baldes, barriles y contenedores.",
    deviceNotConnected: "El dispositivo no se ha conectado a GreaseCloud.",
    lastSeen: "Visto pela última vez",
    never: "Nunca",
    requirement: "Requisito",
    requirements: "Requisitos",
    requirementsText:
      "Los requisitos definen el volumen de grasa que se debe dispensar en un punto final.",
    createRequirements: "Crear requisitos",
    requirementName: "Nombre del requisito",
    rightAmount: "Cantidad correcta",
    rightTime: "Tiempo correcto",
    rightGrease: "Grasa correcta",
    next: "Siguiente",
    autoThreshold: "Umbral automático",
    on: "Encendido",
    off: "Apagado",
    "Over-greased": "Sobreengrasado",
    "Under-greased": "Bajoengrasado",
    submit: "Enviar",
    autoRange: "Auto-rango",
    thresholds: "Umbral",
    barChart: "Gráfico de barras",
    lineChart: "Gráfico de línea",
    capacity: "Capacidad",
    estimatedVolume: "Volumen estimado",
    averageDailyConsumption: "Consumo diario promedio",
    projectedDepletionDate: "Fecha estimada de agotamiento",
    lastReset: "Último reinicio",
    available: "Disponible",
    consumed: "Consumido",
    lastResetLevel: "Nivel de último reinicio",
    verifyLastResetDate:
      "pasado vacío. Verificar fecha de último reinicio del embalse",
    viewReservoir: "Ver embalse",
    noteReservoir: "Nota: Todos los cálculos se realizan en cc.",
    outlets: "Salidas",
    inlets: "Entradas",
    date: "Fecha",
    resetLevel: "Nivel de reinicio",
    resetBy: "Reiniciado por (Fecha)",
    comments: "Comentarios",
    resetDate: "Fecha de reinicio",
    resetReservoir: "Reiniciar embalse",
    override: "Anulación",
    reset: "Reiniciar",
    averageDailyConsumptionTooltip:
      "El consumo diario promedio se calcula en función de los últimos",
    daysOfActivity: "días de actividad.",
    requirementSuccessfully: "El requisito se ha creado correctamente.",
    requirementDeleted:
      "El requisito seleccionado se ha eliminado correctamente.",
    requirementDeletedError:
      "Se produjo un error al intentar eliminar el requisito seleccionado.",
    updateRequirements: "Actualizar requisitos",
    requirementSummary: "Resumen del requisito",
    linkReservoir: "Vincular embalse",
    noReservoirLinked: "No se ha vinculado ningún embalse.",
    addLinkReservoir: "Agregar y vincular embalse",
    unplannedVolumeDispensed: "Volumen dispensado no planificado",
    noRequirementAssigned: "Ningún requisito asignado",
    pleaseAssignRequirement: "Asigne un requisito para ver el cumplimiento.",
    reservoirsReport: "Reporte de Embalses",
    manageAuthTokens: "Administrar tokens de autenticación",
    noRequirementWithinTime: "Ningún requisito dentro del período de tiempo",
    noRequirement: "Sin requisitos",
    estimated: "Fecha estimada",
    depletionDate: "de agotamiento",
    average: "Promedio",
    perDay: "por dia",
    linked: "Vinculados",
    futureReservoirRequirements: "Requisitos futuros del depósitos",
    currentReservoirUsage: "Uso actual del depósito",
    currentMonth: "Mes actual",
    nextMonth: "Próximo mes",
    nextNextMonth: "2 meses",
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
    alerts: "Alertas",
    alert: "Alerta",
    assignedTo: "Asignado a",
    criticality: "Criticidad",
    type: "Tipo",
    enabled: "Habilitado",
    deleteAlert: "Eliminar Alerta",
    createAlert: "Crear Alerta",
    hours: "Horas",
    weeks: "Semanas",
    months: "Meses",
    successfullyUpdated: "actualizado exitosamente",
    successfullyCreated: "creado exitosamente",
    low: "Bajo",
    medium: "Medio",
    high: "Alto",
    critical: "Crítico",
    assignTo: "Asignar a",
    condition: "Condición",
    field: "Campo",
    value: "Valor",
    operator: "Operador",
    unit: "Unidad",
    notifications: "Notificaciones",
    notifyUsers: "Notificar a los siguientes usuarios",
    emails: "Correos electrónicos",
    message: "Mensaje",
    updateAlert: "Actualizar Alerta",
    showAllNotifications: "Mostrar todas las notificaciones",
    result: "Resultado",
    emailSent: "Correo electrónico enviado",
    acknowledged: "Admitido",
    lastEvaluated: "Última evaluación",
    lastNotified: "Última notificación",
    lastTriggered: "Última activación",
    triggeredBy: "Desencadenado por",
    noDataDepletion:
      "Sin datos: se requieren un mínimo de 3 días para calcular el agotamiento",
    equalTo: "Igual a",
    notEqualTo: "No igual a",
    greaterThan: "Mayor que",
    greaterThanOrEqualTo: "Mayor o igual que",
    lessThan: "Menor que",
    lessThanOrEqualTo: "Menor o igual que",
    alertSuccessfullydeleted: "La alerta seleccionada se eliminó correctamente.",
    errorDeletingAlert: "Se produjo un error al intentar eliminar la alerta seleccionada.",
    flowEvents: "Eventos de Flujo",
    pressureEvents: "Eventos de Presión",
    singleOrMultipleChart: "Gráfico Individual o Múltiple",
    pressureMean: "Media de Presión",
    preassureMinMax: "Mín/Máx de Presión",
    pressureTemperature: "Presión Temperatura",
    max: "Máximo",
    min: "Mínimo",
    mean: "Media",
    pressureMin: "Presión Mín",
    pressureMax: "Presión Máx",
    pressure: "Presión",
    flow: "Flujo",
    diagnostic: "Diagnóstica",
    heartbeat: "Pulsación",
    endpointsEvents: "Eventos de Puntos de Extremo",
    pointsEvents: "Eventos de Puntos",
    resetHistory: "Restablecer Historial",
    triggered: "Activado",
    exportToExcel: "Descargar a Excel",
    reservoir: "Reservorio",
    endpoint: "Punto final",
    noTagsAssigned: "No hay etiquetas de grasa asignadas a esta cuenta.",
    uploadCsv: "Subir archivo CSV",
    uploadEndpoints: "Cargar puntos finales",
    uploadCSVText: "Subir archivo CSV:",
    online: "En línea",
    offline: "Fuera de línea",
    offlinePeriod: "Período sin conexión",
    eventType: "Tipo de evento",
    comment: "Comentario",
    reasonCode: "Código de motivo",
    addOnlineOffline: "Agregar evento en línea/fuera de línea",
    manageOperationalState: "Gestionar estado operativo",
    maintenancePlanned: "Mantenimiento (Planificado)",
    maintenanceUnplanned: "Mantenimiento (No Planificado)",
    operationalShutdownPlanned: "Cierre Operativo (Planificado)",
    operationalShutdownUnplanned: "Cierre Operativo (No Planificado)",
    other: "Otro",
    dashboard: "Tablero",
    automaticCompliance: "Cumplimiento Automático",
    manualCompliance: "Cumplimiento Manual",
    inLast7Days: "en los últimos 7 días",
    noDataAvailable: "No hay datos disponibles.",
    seeAll: "Ver Todo",
    withoutRequirement: "Sin Requisito",
    tags: "Etiquetas",
    unmapped: "Sin Mapear",
    withoutSchedule: "Sin Programación",
    nonCompliant: "No Conforme",
    settingsNotConfigured: "La configuración no está configurada para esta cuenta.",
    contactSupport: "Por favor, póngase en contacto con el soporte utilizando el enlace a continuación:",
    noEndpointDeviceAssigned: "Ningún dispositivo final asignado",
    noEventsToDetermine: "No hay eventos para determinar el último visto",
    minFlowRate: "Tasa Mínima de Flujo (L/min)",
    avgFlowRate: "Tasa Promedio de Flujo (L/min)",
    maxFlowRate: "Tasa Máxima de Flujo (L/min)",
    windowSize: "Tamaño de la Ventana (s)",
    samplePeriod: "Período de Muestreo (s)",
    volumeInL: "Volumen (L)",
    minMaxFlowRate: "Tasa Mín./Máx. de Flujo (L/min)",
    flowRate: "Tasa de Flujo",
    copyToken: "Copie y almacene este token. No podrás volver a verlo..",
    tokenCreated: "Token de autenticación creado",
    latitude: "Latitud",
    longitude: "Longitud",
    altitude: "Altitud",
    accuracy: "Precisión",
    speed: "Velocidad",
    speedAccuracy: "Precisión de velocidad",
    heading: "Rumbo",
    gps: "GPS",
    gpsEvents: "Eventos GPS",
    tasks: "Tareas",
    viewTasksText: "Ver tareas dentro de su red GreaseCloud",
    showAllTasksFrom: "Mostrar todas las tareas de",
    pendingTasks: "Informe de Tareas Pendientes",

  },


  datepicker: {
    toggleCalendar: "Alternar calendario",
  },
  calendar: {
    today: "Hoy",
  },
  dateinput: {
    increment: "Incrementar valor",
    decrement: "Disminuir valor",
  },
  datetimepicker: {
    date: "Fecha",
    time: "Hora",
    cancel: "Cancelar",
    set: "Establecer",
    toggleDateTimeSelector: "Alternar selector de fecha y hora",
  },
  numerictextbox: {
    increment: "Incrementar valor",
    decrement: "Disminuir valor",
  },
  timepicker: {
    now: "AHORA",
    set: "Establecer",
    cancel: "Cancelar",
    selectNow: "Seleccionar ahora",
    toggleTimeSelector: "Toggle TimeSelector",
    toggleClock: "Alternar reloj",
  },
  grid: {
    groupPanelEmpty:
      "Arrastre el título de una columna y suéltelo aquí para agrupar por ese criterio",
    noRecords: "No hay datos disponibles.",
    pagerFirstPage: "Ir a la primera página",
    pagerPreviousPage: "Ir a la página anterior",
    pagerNextPage: "Ir a la página siguiente",
    pagerLastPage: "Ir a la última página",
    pagerPage: "Página",
    pagerOf: "de",
    pagerItems: "ítems",
    pagerInfo: "{0} - {1} de {2} ítems",
    pagerItemsPerPage: "ítems por página",
    filterEqOperator: "Es igual a",
    filterNotEqOperator: "No es igual a",
    filterIsNullOperator: "Es nulo",
    filterIsNotNullOperator: "No es nulo",
    filterIsEmptyOperator: "Está vacío",
    filterIsNotEmptyOperator: "No está vacío",
    filterStartsWithOperator: "Comienza con",
    filterContainsOperator: "Contiene",
    filterNotContainsOperator: "No contiene",
    filterEndsWithOperator: "Termina en",
    filterGteOperator: "Es mayor o igual que",
    filterGtOperator: "Es mayor que",
    filterLteOperator: "Es menor o igual que",
    filterLtOperator: "Es menor o igual que",
    filterIsTrue: "Sí",
    filterIsFalse: "No",
    filterBooleanAll: "(Todas)",
    filterAfterOrEqualOperator: "Es posterior o igual a",
    filterAfterOperator: "Es posterior",
    filterBeforeOperator: "Es anterior",
    filterBeforeOrEqualOperator: "Es anterior o igual a",
    filterSubmitButton: "Filtrar",
    filterClearButton: "Limpiar",
    filterAndLogic: "Y",
    filterOrLogic: "O",
    filterTitle: "Filtros",
    sortAscending: "Orden ascendente",
    sortDescending: "Orden descendente",
    filterAriaLabel: "Filtrar",
    filterCheckAll: "Comprobar todo",
    filterChooseOperator: "Elija operador",
    filterSelectedItems: "elementos seleccionados",
    groupColumn: "Columna de grupo",
    pagerTotalPages: "{0}",
    searchPlaceholder: "Búsqueda",
    sortAriaLabel: "Clasificable",
    ungroupColumn: "Desagrupar columna",
  },
  filter: {
    eqOperator: "Es igual a",
    notEqOperator: "No es igual a",
    isNullOperator: "Es nulo",
    isNotNullOperator: "No es nulo",
    isEmptyOperator: "Esta vacio",
    isNotEmptyOperator: "No está vacío",
    startsWithOperator: "Comienza con",
    containsOperator: "Contiene",
    notContainsOperator: "No contiene",
    endsWithOperator: "Termina con",
    gteOperator: "Es mayor o igual a",
    gtOperator: "Es mayor que",
    lteOperator: "Es menor o igual que",
    ltOperator: "Es menos que",
    isTrue: "Es verdad",
    isFalse: "Es falso",
    afterOrEqualOperator: "Es posterior o igual a",
    afterOperator: "Es despues",
    beforeOperator: "Es antes",
    beforeOrEqualOperator: "Es anterior o igual a",
    andLogic: "Y",
    orLogic: "O",
    addExpression: "Agregar expresión",
    addGroup: "Añadir grupo",
    close: "Cerca",
    filterAriaLabel: "Filtrar",
  },
  daterangepicker: {
    swapStartEnd: "Intercambiar valores iniciales y finales",
    start: "Comienzo",
    end: "Fin",
    separator: " ",
  },
  editor: {
    bold: "Audaz",
    italic: "Itálico",
    underline: "Subrayar",
    strikethrough: "Tachado",
    subscript: "Subíndice",
    superscript: "Sobrescrito",
    unlink: "Quitar hipervínculo",
    undo: "Deshacer",
    redo: "Rehacer",
    fontSize: "Tamaño de fuente",
    fontName: "Nombre de la fuente",
    format: "Formato",
    alignLeft: "Alinear texto a la izquierda",
    alignRight: "Alinear texto a la derecha",
    alignCenter: "Texto central",
    alignJustify: "Justificar",
    indent: "Sangrar",
    outdent: "Sangrar",
    orderedList: "Insertar lista ordenada",
    bulletList: "Insertar lista desordenada",
    createTable: "Crea una tabla",
    insertTableHint: "Cree una tabla de {0} x {1}",
    addRowBefore: "Agregar fila arriba",
    addRowAfter: "Agregar fila a continuación",
    addColumnBefore: "Agregar columna a la izquierda",
    addColumnAfter: "Agregar columna a la derecha",
    deleteRow: "Borrar fila",
    deleteColumn: "Eliminar columna",
    deleteTable: "Eliminar tabla",
    mergeCells: "Combinar células",
    splitCell: "Celda dividida",
    hyperlink: "Insertar hipervínculo",
    "hyperlink-dialog-title": "Insertar hipervínculo",
    "hyperlink-dialog-content-address": "dirección web",
    "hyperlink-dialog-content-title": "Título",
    "hyperlink-dialog-content-newwindow": "Abrir enlace en una nueva ventana",
    "hyperlink-dialog-cancel": "Cancelar",
    "hyperlink-dialog-insert": "Insertar",
    image: "Insertar imagen",
    "image-dialog-title": "Insertar imagen",
    "image-address": "dirección web",
    "image-title": "Título",
    "image-altText": "Texto alternativo",
    "image-width": "Ancho (px)",
    "image-height": "Alto (px)",
    "image-cancel": "Cancelar",
    "image-insert": "Insertar",
    viewHtml: "Ver HTML",
    "viewHtml-dialog-title": "Ver HTML",
    "viewHtml-cancel": "Cancelar",
    "viewHtml-update": "Actualizar",
    print: "Impresión",
    selectAll: "Seleccionar todo",
    cleanFormatting: "Formateo limpio",
    pdf: "Exportar como PDF",
    foregroundColor: "Color",
    backgroundColor: "Color de fondo",
    insertFile: "Insertar archivo",
    insertFileDialogTitle: "Insertar archivo",
    insertFileAddress: "dirección web",
    insertFileTitle: "Título",
    insertFileCancel: "Cancelar",
    insertFileInsert: "Insertar",
    findReplaceToolTitle: "Encontrar y reemplazar",
    findReplaceDialogTitle: "Encontrar y reemplazar",
    findReplaceTabFind: "Encontrar",
    findReplaceTabReplace: "Reemplazar",
    findReplaceFindWhat: "Encontrar que:",
    findReplaceReplaceWith: "Reemplazar con",
    findReplaceReplace: "Reemplazar",
    findReplaceReplaceAll: "Reemplaza todo",
    findReplaceMatchCase: "Caso de coincidencia",
    findReplaceMatchWord: "Coincidir solo con la palabra completa",
    findReplaceMatchCyclic: "Coincidir cíclico (envolvente)",
    findReplaceUseRegExp: "Expresión regular",
    findReplacePrevMatch: "Anterior",
    findReplaceNextMatch: "próximo",
    findReplaceMatches: "{0} de {1} coincidencias",
  },
  dropdowns: {
    nodata: "DATOS NO ENCONTRADOS.",
    clear: "Claro",
  },
  upload: {
    cancel: "Cancelar",
    clearSelectedFiles: "Claro",
    dropFilesHere: "Arrastra los archivos aquí para subirlos",
    headerStatusUploaded: "Hecho",
    headerStatusUploading: "Subiendo ...",
    invalidFileExtension: "Tipo de archivo no permitido.",
    invalidFiles:
      "Archivo (s) inválidos. Verifique los requisitos de carga de archivos.",
    invalidMaxFileSize: "Tamaño de archivo demasiado grande.",
    invalidMinFileSize: "Tamaño de archivo demasiado pequeño.",
    remove: "Eliminar",
    retry: "Rever",
    select: "Selecciona archivos...",
    uploadSelectedFiles: "Subir",
    total: "Total",
    files: "archivos",
    dropZoneHint: "Arrastre y suelte archivos aquí para cargarlos.",
    dropZoneNote: "Solo se permiten archivos JPEG y PNG.",
    statusUploadFailed: "Los archivos no se pudieron cargar.",
    statusUploaded: "Archivo (s) subidos correctamente.",
  },
  sortable: {
    noData: "Sin datos",
  },
  pager: {
    itemsPerPage: "elementos por página",
    info: "{0} - {1} de {2} elementos",
    firstPage: "Ir a la primera pagina",
    previousPage: "Ir a la pagina anterior",
    nextPage: "Ir a la página siguiente",
    lastPage: "Ir a la última página",
    page: "Página",
    of: "de",
    pagerTotalPages: "{0}",
  },
  treelist: {
    filterClearButton: "Claro",
    filterEqOperator: "Es igual a",
    filterNotEqOperator: "No es igual a",
    filterIsNullOperator: "Es nulo",
    filterIsNotNullOperator: "No es nulo",
    filterIsEmptyOperator: "Esta vacio",
    filterIsNotEmptyOperator: "No está vacío",
    filterStartsWithOperator: "Comienza con",
    filterContainsOperator: "Contiene",
    filterNotContainsOperator: "No contiene",
    filterEndsWithOperator: "Termina con",
    filterGteOperator: "Es mayor o igual a",
    filterGtOperator: "Es mayor que",
    filterLteOperator: "Es menor o igual que",
    filterLtOperator: "Es menos que",
    filterIsTrue: "Es verdad",
    filterIsFalse: "Es falso",
    filterBooleanAll: "(Todo)",
    filterAfterOrEqualOperator: "Es posterior o igual a",
    filterAfterOperator: "Es despues",
    filterBeforeOperator: "Es antes",
    filterBeforeOrEqualOperator: "Es anterior o igual a",
    noRecords: "No hay registros disponibles",
  },
  scheduler: {
    editorValidationRequired: "Se requiere campo.",
    editorValidationStart:
      "La hora de inicio debe ser anterior a la hora de finalización.",
    editorValidationEnd:
      "La hora de finalización debe ser posterior a la hora de inicio.",
    allEvents: "Todos los eventos",
    allDay: "todo el dia",
    today: "Hoy dia",
    dayViewTitle: "Día",
    monthViewTitle: "Mes",
    weekViewTitle: "Semana",
    workWeekViewTitle: "Semana de trabajo",
    timelineViewTitle: "Cronología",
    agendaViewTitle: "Agenda",
    deleteTitle: "Borrar",
    previousTitle: "Anterior",
    nextTitle: "próximo",
    showFullDay: "Mostrar día completo",
    showWorkDay: "Mostrar horario comercial",
    editorOccurrence: "Editar ocurrencia actual",
    editorSeries: "Edita la serie",
    editorRecurringConfirmation:
      "¿Quieres editar solo la ocurrencia de este evento o toda la serie?",
    editorRecurringDialogTitle: "Editar artículo recurrente",
    editorSave: "Ahorrar",
    editorCancel: "Cancelar",
    editorDelete: "Borrar",
    editorTitle: "Evento",
    editorEventTitle: "Título",
    editorEventStart: "Comienzo",
    editorEventStartTimeZone: "Zona horaria de inicio",
    editorEventEnd: "Fin",
    editorEventEndTimeZone: "Zona horaria de finalización",
    editorEventAllDay: "Evento de todo el día",
    editorEventDescription: "Descripción",
    editorEventSeparateTimeZones: "Termina en una zona horaria diferente",
    editorEventTimeZone: "Especificar zona horaria",
    recurrenceEditorRepeat: "Repetir",
    recurrenceEditorDailyInterval: "días)",
    recurrenceEditorDailyRepeatEvery: "Repite cada",
    recurrenceEditorWeeklyInterval: "semanas)",
    recurrenceEditorWeeklyRepeatEvery: "Repite cada",
    recurrenceEditorWeeklyRepeatOn: "Repetir en",
    recurrenceEditorMonthlyDay: "Día",
    recurrenceEditorMonthlyInterval: "meses)",
    recurrenceEditorMonthlyRepeatEvery: "Repite cada",
    recurrenceEditorMonthlyRepeatOn: "Repetir en",
    recurrenceEditorYearlyOf: "de",
    recurrenceEditorYearlyRepeatEvery: "Repite cada",
    recurrenceEditorYearlyRepeatOn: "Repetir en",
    recurrenceEditorYearlyInterval: "años)",
    recurrenceEditorFrequenciesDaily: "A diario",
    recurrenceEditorFrequenciesMonthly: "Mensual",
    recurrenceEditorFrequenciesNever: "Nunca",
    recurrenceEditorFrequenciesWeekly: "Semanalmente",
    recurrenceEditorFrequenciesYearly: "Anual",
    recurrenceEditorOffsetPositionsFirst: "Primero",
    recurrenceEditorOffsetPositionsSecond: "Segundo",
    recurrenceEditorOffsetPositionsThird: "Tercero",
    recurrenceEditorOffsetPositionsFourth: "Cuatro",
    recurrenceEditorOffsetPositionsLast: "Ultimo",
    recurrenceEditorWeekdaysDay: "Día",
    recurrenceEditorWeekdaysWeekday: "Día laborable",
    recurrenceEditorWeekdaysWeekendday: "Día de fin de semana",
    recurrenceEditorEndAfter: "Después",
    recurrenceEditorEndOccurrence: "ocurrencia (s)",
    recurrenceEditorEndLabel: "Fin",
    recurrenceEditorEndNever: "Nunca",
    recurrenceEditorEndOn: "Sobre",
    deleteConfirmation: "¿Estás seguro de que deseas eliminar este evento?",
    deleteRecurringConfirmation:
      "¿Quiere eliminar solo esta ocurrencia de evento o toda la serie?",
    deleteOccurrence: "Eliminar ocurrencia actual",
    deleteSeries: "Eliminar la serie",
    deleteDialogTitle: "Eliminar evento",
    deleteRecurringDialogTitle: "Eliminar artículo recurrente",
    dateTitle: "Fecha",
    timeTitle: "Hora",
    eventTitle: "Evento",
    noEvents: "no hay eventos",
  },
  listbox: {
    moveUp: "Ascender",
    moveDown: "Mover hacia abajo",
    transferTo: "Transferir a",
    transferFrom: "Transferido de",
    transferAllTo: "Transferir todo a",
    transferAllFrom: "Transferir todo desde",
    remove: "Borrar",
  },
  stepper: {
    optionalText: "(Opcional)",
  },
  labels: {
    labelsOptional: "(Opcional)",
  },
  slider: {
    increment: "Aumento",
    decrement: "Disminución",
    dragTitle: "Arrastrar",
  },
  colorGradient: {
    r: "r",
    g: "gramo",
    b: "B",
    a: "a",
    hex: "maleficio",
    contrastRatio: "Relación de contraste",
    colorGradientAALevel: "Automóvil club británico",
    colorGradientAAALevel: "AAA",
    colorGradientPass: "Aprobar",
    colorGradientFail: "Fallar",
  },
  checkbox: {
    validation: "¡Marque esta casilla si desea continuar!",
    optionalText: "(Opcional)",
  },
  radioButton: {
    validation: "¡Seleccione la opción si desea continuar!",
  },
  switch: {
    validation: "¡Enciéndelo si quieres continuar!",
  },
  gantt: {
    weekViewTitle: "Semana",
    dayViewTitle: "Día",
    monthViewTitle: "Mes",
    yearViewTitle: "Año",
    filterClearButton: "Claro",
    filterEqOperator: "Es igual a",
    filterNotEqOperator: "No es igual a",
    filterIsNullOperator: "Es nulo",
    filterIsNotNullOperator: "No es nulo",
    filterIsEmptyOperator: "Esta vacio",
    filterIsNotEmptyOperator: "No está vacío",
    filterStartsWithOperator: "Comienza con",
    filterContainsOperator: "Contiene",
    filterNotContainsOperator: "No contiene",
    filterEndsWithOperator: "Termina con",
    filterGteOperator: "Es mayor o igual a",
    filterGtOperator: "Es mayor que",
    filterLteOperator: "Es menor o igual que",
    filterLtOperator: "Es menos que",
    filterIsTrue: "Es verdad",
    filterIsFalse: "Es falso",
    filterBooleanAll: "(Todo)",
    filterAfterOrEqualOperator: "Es posterior o igual a",
    filterAfterOperator: "Es despues",
    filterBeforeOperator: "Es antes",
    filterBeforeOrEqualOperator: "Es anterior o igual a",
    noRecords: "No hay registros disponibles",
    editorSave: "Ahorrar",
    editorCancel: "Cancelar",
    editorTitle: "Tarea",
    editorTaskTitle: "Título",
    editorTaskStart: "Comienzo",
    editorTaskEnd: "Fin",
    editorTaskComplete: "Completo",
    editorValidationRequired: "Se requiere campo.",
    editorValidationStart:
      "La hora de inicio debe ser anterior a la hora de finalización.",
    editorValidationEnd:
      "La hora de finalización debe ser posterior a la hora de inicio.",
    addTask: "Agregar tarea",
    addChild: "Agregar niño",
    addAbove: "Agregar arriba",
    addBelow: "Agrega abajo",
    editorDelete: "Borrar",
    deleteConfirmation: "¿Estás seguro de que deseas eliminar este evento?",
    deleteDialogTitle: "Eliminar evento",
  },
  multiviewcalendar: {
    prevView: "Ir a la vista anterior",
    nextView: "Navegar a la siguiente vista",
  },
  columnMenu: {
    columnMenuFilterClearButton: "Claro",
    columnMenuFilterSubmitButton: "Filtrar",
    columnMenuFilterTitle: "Filtrar",
    columnMenuSortAscending: "Orden ascendente",
    columnMenuSortDescending: "Orden descendiente",
    columnMenuFilterEqOperator: "Es igual a",
    columnMenuFilterNotEqOperator: "No es igual a",
    columnMenuFilterIsNullOperator: "Es nulo",
    columnMenuFilterIsNotNullOperator: "No es nulo",
    columnMenuFilterIsEmptyOperator: "Esta vacio",
    columnMenuFilterIsNotEmptyOperator: "No está vacío",
    columnMenuFilterStartsWithOperator: "Comienza con",
    columnMenuFilterContainsOperator: "Contiene",
    columnMenuFilterNotContainsOperator: "No contiene",
    columnMenuFilterEndsWithOperator: "Termina con",
    columnMenuFilterGteOperator: "Es mayor o igual a",
    columnMenuFilterGtOperator: "Es mayor que",
    columnMenuFilterLteOperator: "Es menor o igual que",
    columnMenuFilterLtOperator: "Es menos que",
    columnMenuFilterIsTrue: "Es verdad",
    columnMenuFilterAfterOrEqualOperator: "Es posterior o igual a",
    columnMenuFilterAfterOperator: "Es despues",
    columnMenuFilterBeforeOperator: "Es antes",
    columnMenuFilterBeforeOrEqualOperator: "Es anterior o igual a",
    columnMenuFilterAndLogic: "Y",
    columnMenuFilterOrLogic: "O",
  },
  pivotgrid: {
    fieldMenuReset: "Reiniciar",
    fieldMenuApply: "Solicitar",
    configuratorCancel: "Cancelar",
    configuratorApply: "Solicitar",
    configuratorTitle: "Ajustes",
    configuratorEditorSearchTitle: "Campos",
    configuratorEditorSearchPlaceholder: "Búsqueda...",
    configuratorColumnsTitle: "Columnas",
    configuratorRowsTitle: "Filas",
    configuratorMeasuresTitle: "Valores",
    configuratorButtonLabel: "Cambiar ajustes",
    configuratorMissingFields:
      "Seleccione algunos campos para comenzar la configuración",
  },
};
