/**
 * This hook returns state necessary for managing GB Table controls.
 */

import React, { useEffect, useRef } from "react";
import useLayoutStore from "../../../Zustand/layoutStore";
import { getKeyFromAccessor } from "../Utilities";

export interface HasId {
  id: string;
}
export interface KendoTableState<Type extends HasId> {
  initialAssets: Array<Type>;
  tempRows: Array<Type>;
  resetState: () => void;
  setTempRows: React.Dispatch<React.SetStateAction<Type[]>>;
  assets: Array<Type>;
  setAssets: React.Dispatch<React.SetStateAction<Type[]>>;
  modifiedRowIds: Array<string>;
  setModifiedRowIds: (updated: Array<string>) => any;
  deleteModalOpen: boolean;
  setDeleteModalOpen: (update: boolean) => any;
  selectedRowsIds: Array<string>;
  setSelectedRowIds: (updated: Array<string>) => any;
  canModifyRow: boolean;
  setCanModifyRow: (update: boolean) => any;
  canDiscard: boolean;
  setCanDiscard: (update: boolean) => any;
  canSave: boolean;
  setCanSave: (update: boolean) => any;
  onClose: () => any;
  onCloseDelete: () => any;
  isLoading: boolean;
  setIsLoading: (update: boolean) => any;
  onCellChanged: (rowId: string, initialValue: any, value: any, accessor: (x: any) => any) => void;
  DefaultAssetCell: any;
  test: (rowId: string) => any;
}

export default function useKendoTableState<Type extends HasId>(props: {
  key: string;
  initialAssets: Array<Type>;
  overrideOnCellChanged?: () => any;
}): KendoTableState<Type> {
  //Provide the following pieces of state
  const [initialAssets, setInitialAssets] = React.useState<Array<Type>>(props.initialAssets);
  const [assets, setAssets] = React.useState<Array<Type>>(props.initialAssets);
  const [tempRows, setTempRows] = React.useState<Array<Type>>([]);
  const tempRowLengthRef = useRef<number>(0);
  const [modifiedRowIds, setModifiedRowIds] = React.useState<Array<string>>([]);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const [selectedRowsIds, setSelectedRowIds] = React.useState<Array<string>>([]);
  const [canModifyRow, setCanModifyRow] = React.useState<boolean>(false);
  const [canDiscard, setCanDiscard] = React.useState<boolean>(false);
  const [canSave, setCanSave] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const layoutState = useLayoutStore();

  //We keep track of the length of the temp row for use in defaultCellChanged callback
  useEffect(() => {
    tempRowLengthRef.current = tempRows.length;
  }, [tempRows.length]);

  //Keep track of global modified data - add rows only.
  useEffect(() => {
    //Modified count for this table is the temprows plus the modified rows.
    layoutState.setModifiedDataCounts({
      ...layoutState.tableModifiedDataCounts,
      [props.key]: tempRows.length + modifiedRowIds.length,
    });

    //On dismount, we set the modified data count for this table to zero
    return () => {
      if (tempRows.length === 1) {
        layoutState.setModifiedDataCounts({
          ...layoutState.tableModifiedDataCounts,
          [props.key]: 0,
        });
      }
    };
  }, [tempRows.length, modifiedRowIds.length]);

  const onClose = () => {
    setDeleteModalOpen(false);
    setCanSave(false);
    setCanDiscard(false);
  };
  const onCloseDelete = () => setDeleteModalOpen(!deleteModalOpen);

  const resetState = () => {
    setAssets(props.initialAssets);
    setTempRows([]);
    setModifiedRowIds([]);
    setDeleteModalOpen(false);
    setCanDiscard(false);
    setSelectedRowIds([]);
    setDeleteModalOpen(false);
    setCanSave(false);
    setCanModifyRow(false);
  };

  //If initial assets change, update assets
  React.useEffect(() => {
    resetState();
  }, [props.initialAssets]);

  //Check to ensure that discard and save are disabled if its not possible to edit
  React.useEffect(() => {
    if (canDiscard === true) {
      setCanDiscard(false);
    }
    if (canSave === true) {
      setCanSave(false);
    }
  }, [canModifyRow]);

  //Default function to run when a cell is changed within the table.
  const defaultOnCellChanged = (rowId: string, initialValue: any, value: any, accessor: (x: any) => any) => {
    //Get updated key from accessor
    const updatedKey = getKeyFromAccessor(accessor);

    //Account for any temp cells that exist
    if (tempRows.length > 0) {
      setTempRows((old) => {
        //Find index associated with the provided id
        let updatedAssets = [{ ...old[0], [updatedKey]: value }];
        return updatedAssets;
      });
      return;
    }

    // rowIndex = rowIndex - tempRowLengthRef.current;

    //Keep track of modified rows - checks
    setModifiedRowIds((old) => {
      //If it's not already included
      if (!old.includes(rowId)) {
        //Check the new row is different to the old row
        if (initialValue !== value) {
          //If different, append to modified rows
          const newModifiedRows = [...old, rowId];
          return newModifiedRows;
        }
      }
      return old;
    });

    //Update the data
    setAssets((old) => {
      //Find index associated with the provided id
      const modifiedRowIndex = old.findIndex((x) => x.id === rowId);
      let updatedAssets = [...old];
      updatedAssets[modifiedRowIndex] = {
        ...updatedAssets[modifiedRowIndex],
        [updatedKey]: value,
      };
      return updatedAssets;
    });
  };

  const test = (rowId: string) => {
    //Keep track of modified rows - checks
    setModifiedRowIds((old) => {
      //If it's not already included
      if (!old.includes(rowId)) {
          //If different, append to modified rows
          const newModifiedRows = [...old, rowId];
          return newModifiedRows;
      }
      return old;
    });
  }

  // Editable cell renderer for Area, passes down to GBTable.
  const DefaultAssetCell = (id: any, value: any, initialValue: any, onChange: any, onBlur: any) => {
    // onKeyPress not used as it doesnt pick up escape
    return <input disabled={!canModifyRow} value={value} onChange={onChange} onBlur={onBlur} />;
  };

  return {
    DefaultAssetCell,
    onCellChanged: props.overrideOnCellChanged ?? defaultOnCellChanged,
    resetState,
    assets,
    tempRows,
    setTempRows,
    setAssets,
    modifiedRowIds,
    setModifiedRowIds,
    deleteModalOpen,
    setDeleteModalOpen,
    selectedRowsIds,
    setSelectedRowIds,
    canModifyRow,
    setCanModifyRow,
    canDiscard,
    setCanDiscard,
    canSave,
    setCanSave,
    onClose,
    onCloseDelete,
    initialAssets,
    isLoading,
    setIsLoading,
    test,
  };
}
