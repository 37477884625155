/**
 * This component handles high level logic for events, providing implementation of queries and mutations
 * via the eventApiService. It gathers data and provides it to the event table for display when all relevant
 * data has been retrieved.
 *
 * Date: 10/03/2022
 */
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { QueryClient, useQuery } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import EventReadDto from "../../../Models/Event/EventReadDto";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import eventApiService, { EventFilter } from "../Utilities/EventsApiService";
import { PointReadDto } from "../../../Models/Point/PointReadDto";
import { MachineReadDto } from "../../../Models/Machine/MachinesReadDto";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import consumablesApiService from "../../Consumables/Utilities/ConsumablesApiService";
import areasApiService from "../../Area/Utilities/AreasApiService";
import machinesApiService from "../../Machines/Utilities/MachinesApiService";
import pointsApiService from "../../Points/Utilities/PointsApiService";
import EventReadTableDto from "../../../Models/Event/EventReadTableDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { DeviceReadDto } from "../../../Models/Device/DeviceReadDto";
import devicesApiService from "../../Devices/Utilities/DevicesApiService";
import { ScheduleReadDto } from "../../../Models/Schedule/ScheduleReadDto";
import schedulesApiService from "../../Schedules/Utilities/ScheduleApiService";
import { TaskReadDto } from "../../../Models/Task/TaskReadDto";
import tasksApiService from "../../Tasks/Utilities/TasksApiService";
import PointsEventTable from "../Components/PointsEventTable";

interface Props {
  eventFilter: any
}

export default function PointsEventsPage(props: Props): ReactElement {

  const localizationService = useLocalization();

  const eventFilter = props.eventFilter

  //QueriesForPage
  const eventsQuery = useQuery<Array<EventReadDto>>([ReactQueryKeys.AllEventsQuery, eventFilter], async () => {
    return eventApiService.getAllPointsEvents(eventFilter);
  });

  const pointsQuery = useQuery<Array<PointReadDto>>(ReactQueryKeys.AllPointsQuery, async () => {
    return pointsApiService.getAllPoints();
  });

  const machinesQuery = useQuery<Array<MachineReadDto>>(ReactQueryKeys.AllMachinesQuery, async () => {
    return machinesApiService.getAllMachines();
  });

  const areasQuery = useQuery<Array<AreaReadDto>>(ReactQueryKeys.AllAreasQuery, async () => {
    return areasApiService.getAllAreas();
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  const devicesQuery = useQuery<Array<DeviceReadDto>>(ReactQueryKeys.AllDevicesQuery, async () => {
    return devicesApiService.getAllDevices();
  });

  const schedulesQuery = useQuery<Array<ScheduleReadDto>>(ReactQueryKeys.AllSchedulesQuery, async () => {
    return schedulesApiService.getAllSchedules();
  });

  const tasksQuery = useQuery<Array<TaskReadDto>>(ReactQueryKeys.AllTasksQuery, async () => {
    return tasksApiService.getAllTasks();
  });

  /**
   * This function prepares data for the event table, including injecting additional metadata.
   */
  const eventData = useMemo(() => {
    //Create hashmap of relevant id -> name pairs.
    const areaIdtoName: { [id: string]: string } = {};
    const consumableIdtoName: { [id: string]: string } = {};
    const machineIdtoName: { [id: string]: string } = {};
    const pointIdtoName: { [id: string]: string } = {};
    const deviceIdtoName: { [id: string]: string } = {};
    const scheduleIdtoName: { [id: string]: string } = {};
    const pointIdToTask: { [id: string]: TaskReadDto } = {};
    areasQuery.data?.forEach((area) => {
      areaIdtoName[area.id] = area.name;
    });
    consumablesQuery.data?.forEach((consumable) => {
      consumableIdtoName[consumable.id] = consumable.name;
    });
    machinesQuery.data?.forEach((machine) => {
      machineIdtoName[machine.id] = machine.name;
    });
    pointsQuery.data?.forEach((point) => {
      pointIdtoName[point.id] = point.name;
    });
    devicesQuery.data?.forEach((device) => {
      deviceIdtoName[device.id] = device.serialNumber;
    });
    schedulesQuery.data?.forEach((schedule) => {
      scheduleIdtoName[schedule.id] = schedule.name;
    });
    tasksQuery.data?.forEach((task) => {
      pointIdToTask[task.point.id] = task;
    });
    
    //Map over machines to inject relevant data
    const results = eventsQuery.data?.map((event) => {
      return {
        ...event,
        occurred: new Date(event.occurred),
        areaName: areaIdtoName[event.areaId] ?? null,
        consumableName: consumableIdtoName[event.consumableId] ?? null,
        machineName: machineIdtoName[event.machineId] ?? null,
        pointName: pointIdtoName[event.pointId] ?? null,
        serialNumber: deviceIdtoName[event.deviceId] ?? null,
        scheduleId: pointIdToTask[event.pointId]?.scheduleId === null ? "" : pointIdToTask[event.pointId]?.scheduleId,
        scheduleName: scheduleIdtoName[pointIdToTask[event.pointId]?.scheduleId] === undefined ? "No Schedule" : scheduleIdtoName[pointIdToTask[event.pointId]?.scheduleId],
        occurredWithoutTime: new Date(new Date(event.occurred).setHours(0,0,0)),
        createdWithoutTime: new Date(new Date(event.created).setHours(0,0,0)),
        volumeDispensedRound: Number(event.volumeDispensed).toFixed(2)
      } as EventReadTableDto;
    });

    return results;
  }, [eventsQuery.data, areasQuery.data, consumablesQuery.data, machinesQuery.data, pointsQuery.data, devicesQuery.data, schedulesQuery.data, tasksQuery.data]);

  //If the events filters are modified then refetch events.
  useEffect(() => {
    eventsQuery.refetch();
  }, [eventFilter]);

  /** RENDER **/
  if (
    eventsQuery.isLoading ||
    areasQuery.isLoading ||
    pointsQuery.isLoading ||
    machinesQuery.isLoading ||
    consumablesQuery.isLoading ||
    devicesQuery.isLoading ||
    schedulesQuery.isLoading ||
    tasksQuery.isLoading ||
    eventsQuery.isFetching ||
    areasQuery.isFetching ||
    pointsQuery.isFetching ||
    machinesQuery.isFetching ||
    consumablesQuery.isFetching ||
    devicesQuery.isFetching ||
    schedulesQuery.isFetching ||
    tasksQuery.isFetching
  ) {
    return <LoadingSpinner />;
  } else {
    if (eventsQuery.error || areasQuery.error || pointsQuery.error || machinesQuery.error || consumablesQuery.error || devicesQuery.error || schedulesQuery.isLoading || tasksQuery.isLoading) {
      return <ErrorCard />;
    } else {
      return (
        <div className="">
          <PointsEventTable
            events={eventData!}       
            />
         
         </div>
      );
    }
  }
}
