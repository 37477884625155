import React, { ReactElement, useEffect, useMemo, useState } from "react";
import EventReadTableDto from "../../../../Models/Event/EventReadTableDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import moment from "moment";
import { SortDescriptor, process, State } from "@progress/kendo-data-query";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn as Column, GridToolbar} from "@progress/kendo-react-grid";
import { CustomTextFilterCell } from "../../../Shared/Components/Table/CustomTextFilterCell";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface Props {
  events: Array<EventReadTableDto> | any;
  aggregator: string;
}

interface Page {
  skip: number;
  take: number;
}

const page: Page = { skip: 0, take: 30 };

export default function EndpointEventsTableAssets(props: Props): ReactElement {
  const localizationService = useLocalization();
  const aggregator = props.aggregator;
  const initialSort: Array<SortDescriptor> = [{ field: "timestamp", dir: "desc" }];
  const [sort, setSort] = React.useState(initialSort);
  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

   //Creating dataState to prevent issues with virtual scrolling
   const createAppState = (dataState: State) => {
    return {
      result: process(props.events, dataState),
      dataState: dataState,
    };
  };

  const [dataState, setDataState] = useState<any>(
    createAppState({
      skip: page.skip,
      take: page.take,
      sort: sort
    })
  );
  
  const dataStateChange = (event) => {
    setDataState(createAppState(event.dataState));
  };

   useEffect(() => {
    setDataState( createAppState({
      skip: page.skip,
      take: page.take,
      sort: sort
    }));
  }, [props]);

  //Formating Cells
  const OccurredCell = (props: any) => {
    return (
      <td>
        {aggregator === 'none' ? 
          <div>{moment(props.dataItem.timestamp).format('DD/MM/YYYY @ HH:mm:ss')}</div>
          :
          <div>{moment(props.dataItem.timestamp).format('DD/MM/YYYY')}</div>
        }
      </td>
      );
  }

  const UploadedCell = (props: any) => {
    return (
      <td>
        <div>{moment(props.dataItem.synced).format('DD/MM/YYYY @ HH:mm:ss')}</div>
      </td>
      );
  }

  const exportData = useMemo(() => {
    return props.events.map((item) => ({
      ...item,
      timestampExport: props.aggregator === "none" ? moment(item.timestamp).format('DD/MM/YYYY HH:mm:ss') : moment(item.timestamp).format('DD/MM/YYYY'),
      syncedExport: props.aggregator === "none" ? moment(item.synced).format('DD/MM/YYYY HH:mm:ss') : moment(item.synced).format('DD/MM/YYYY'),
      
    }));
  }, [props.events]);

  const getExcelExportData = () => {
    return process(exportData, {filter: dataState.dataState.filter, sort: dataState.dataState.sort}).data;
  };

  return (
    <div>
      <ExcelExport data={getExcelExportData()} ref={_export} collapsible={true} fileName={`${'Endpoints Events'}.xlsx`}>
        {aggregator === 'none' && (
      <ExcelExportColumn field="timestampExport" title={`${localizationService.toLanguageString('custom.occurred', enMessages.custom.occurred)}`} cellOptions={{ format: "dd/mm/yyyy hh:mm:ss" }} width={150}/>
        )}
        <ExcelExportColumn field="syncedExport" title={`${localizationService.toLanguageString('custom.uploaded', enMessages.custom.uploaded)}`} cellOptions={{ format: "dd/mm/yyyy hh:mm:ss" }} width={150}/>
        <ExcelExportColumn field="serialNo" title={`${localizationService.toLanguageString('custom.serialNumber', enMessages.custom.serialNumber)}`} /> 
        <ExcelExportColumn field="endpointName" title={`${localizationService.toLanguageString('custom.name', enMessages.custom.name)}`} /> 
        <ExcelExportColumn field="consumableName" title={`${localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)}`} />
        <ExcelExportColumn field="volumeDispensed" title={`${localizationService.toLanguageString('custom.volumeDispensed', enMessages.custom.volumeDispensed)}`} cellOptions={{ format: "0.0000" }} />
      </ExcelExport>
      <Grid
         key={props.events.length.toString()}
         rowHeight={46}
         take={page.take}
         data={dataState.result}
         scrollable={"virtual"}
         filterable={true}
         onDataStateChange={dataStateChange}
         {...dataState.dataState}
         sortable={true}
         style={{
           height: `680px`,
         }}
        className=" w-full z-0 extra-filter-button activity-table "
      >
        <GridToolbar>  
        <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
            <div className="gb-h3 pl-1">{localizationService.toLanguageString('custom.events', enMessages.custom.events)}</div>
              <Tooltip anchorElement="target" position="bottom">
                <Button
                  title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
                  icon="download"
                  disabled={dataState.result.data.length === 0}
                  onClick={excelExport}
                />
              </Tooltip>
          </div>
        </GridToolbar>
        <Column field="timestamp" title={`${localizationService.toLanguageString('custom.occurred', enMessages.custom.occurred)}`}  cell={OccurredCell} filterable={false}/>
        {aggregator === 'none' && (
          <Column field="synced" title={`${localizationService.toLanguageString('custom.uploaded', enMessages.custom.uploaded)}`}  cell={UploadedCell} filterable={false}/>
        )}
        <Column field="serialNo" title={`${localizationService.toLanguageString('custom.serialNumber', enMessages.custom.serialNumber)}`}   filterCell={CustomTextFilterCell}/>
        <Column field="endpointName" title={`${localizationService.toLanguageString('custom.name', enMessages.custom.name)}`}  filterCell={CustomTextFilterCell} />
        <Column field="consumableName" title={localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)} filterCell={CustomTextFilterCell} />
        <Column field="volumeDispensedRound" title={localizationService.toLanguageString('custom.volumeDispensed', enMessages.custom.volumeDispensed)} filterCell={CustomTextFilterCell}/>
      </Grid>     
  </div>
  );
}