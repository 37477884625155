export const enMessages = {
  custom: {
    language: "Language",
    profile: "Go to Profile",
    changeAccount: "Change Account",
    logout: "Log Out",
    accountSetup: "Account Setup",
    uploadData: "Upload Account Data",
    step1: "Step 1: Download and populate the account upload template",
    step2: "Step 2: Upload account template file",
    seeUploadTemplate: "See Upload Template for more information",
    downloadTemplate: "Download Template",
    errors: "Errors",
    downloadData: "Download Account Data",
    inventory: "Inventory",
    schedules: "Schedules",
    locations: "Locations",
    greaseTags: "Grease Tags",
    routeEditor: "Route Editor",
    devices: "Devices",
    assets: "Assets",
    events: "Events",
    compliance: "Compliance",
    administration: "Administration",
    manageUsers: "Manage Users",
    manageDevices: "Manage Devices",
    manageAccounts: "Manage Accounts",
    supportCentre: "Support Centre",
    cancel: "Cancel",
    confirm: "Confirm",
    confirmLeave: "Are you sure you wish to leave this page?",
    unsavedChanges:
      "There may still be unsaved changes on this page, if you press confirm you will be navigated away and all unsaved changes will be lost.",
    unsavedChangesShort: "All unsaved changes will be lost.",
    howTo: "How-To Video Guides",
    howToView: "View our latest how-to video guides",
    howToBtn: "View how-to videos",
    password: "Password",
    supportDocumentation: "Support Documentation",
    supportDocumentationView:
      "View our latest support documentation hosted online.",
    supportDocumentatioBtn: "View support documentation",
    support: "Support",
    supportText:
      "Contact our support team and get their guidance on issues or questions related to your products.",
    supportRequest: "Raise a support request",
    supportEmail: "Send us an email",
    greaseTypes: "Grease Types",
    greaseTypesText:
      "Please register the different grease types required by machinery within your account.",
    schedulesText:
      "Schedules define the frequency and dates on which tags in your account require greasing.",
    locationsText:
      "Different zones within your account which contain assets and points for greasing.",
    assetsText:
      "Assets are pieces of plant within your account that are fitted with greasing tags.",
    greaseTagsText:
      "Greasing points associated with assets within your account associated with Greaseboss greasing tags.",
    routeEditorText:
      "Represents the order in which greasing points should be greased.",
    devicesText:
      "Represents Greaseboss head units registered within your account.",
    name: "Name",
    description: "Description",
    manufacturer: "Manufacturer",
    consumables: "Consumables",
    actions: "Actions",
    add: "Add",
    exit: "Exit",
    edit: "Edit",
    delete: "Delete",
    discardChanges: "Discard Changes",
    save: "Save",
    saveChanges: "Save Changes?",
    saveChangesConfirmation:
      "Are you sure you want to save the changes made to the selected items?",
    continue: "Continue",
    deleteItems: "Delete Items?",
    deleteItemsConfirmation:
      "Are you sure that you'd like to delete the selected items?",
    exportCSV: "Export as CSV",
    search: "Search...",
    frequency: "Frequency",
    recurrence: "Recurrence",
    timezone: "Timezone",
    startDate: "Start Date",
    summary: "Summary",
    addSchedule: "Add Schedule",
    mustSelectDay:
      "A day must be selected for a week for a Weekly or Monthly Schedule.",
    error: "Error",
    scheduleSuccessfully: "schedule has been successfully",
    updated: "updated",
    created: "Created",
    somethingWentWrong: "Something went wrong.",
    dailyRecurrenceInterval: "Daily Recurrence Interval",
    recurranceXDays: "Recurring Every X Days",
    weeklyRecurrenceInterval: "Weekly Recurrence",
    recurranceXWeeks: "Recurring Every X Weeks",
    recurranceXMonths: "Recurring Every X Months",
    recurrenceInterval: "Recurrence Interval",
    mustSelectWeek: "At least one day must be selected for the week",
    monthlyRecurrence: "Monthly Recurrence",
    mustSelectMonth: "At least one day must be selected for the month",
    updateSchedule: "Update Schedule",
    createSchedule: "Create Schedule",
    scheduleName: "Schedule Name",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    endDate: "End Date",
    parentLocation: "Parent Location",
    descriptionNotes: "Description/Notes",
    model: "Model",
    parentAsset: "Parent Asset",
    numTags: "# of Tags",
    location: "Location",
    order: "Order",
    asset: "Asset",
    consumable: "Consumable",
    volume: "Volume (cc)",
    rfid: "RFID",
    schedule: "Schedule",
    tagName: "Tag Name",
    grease: "Grease",
    modelNumber: "Model Number",
    serialNumber: "Serial Number",
    firmwareVersion: "Firmware Version",
    occurred: "Occurred",
    point: "Point",
    volumeDispensed: "Volume Dispensed (cc)",
    eventsText:
      "View events posted by devices within your GreaseCloud network.",
    showEventsFrom: "Show all events From",
    until: "Until",
    email: "Email",
    currentRole: "Current Role",
    confirmed: "Confirmed",
    currentAccount: "Current Account",
    user: "User",
    updateRoles: "Update Roles",
    resendInvite: "Resend Invite",
    manageUsersText:
      "Grant access to new users, revoke access, and assign user roles.",
    id: "ID",
    account: "Account",
    device: "Device",
    manageDevicesText:
      "Create identities for new devices, update their accounts, and monitor usage.",
    manageAccountsText:
      "Add, remove and update accounts with access to the GreaseCloud system.",
    deviceCreation: "Device Creation Success",
    deviceCreationText1:
      "The device was successfully created with the following credentials, please review the following details and store them in a safe place.",
    deviceCreationText2:
      "You will not be able to view these details again after closing this window.",
    secretKey: "SECRET KEY",
    close: "Close",
    roleRemoved: "The user role has been successfully removed",
    roleRemovedError:
      "Something went wrong whilst attempting to remove the user role.",
    roleAdded: "The user role has been successfully added",
    roleAddedError:
      "Something went wrong whilst attempting to add the user role.",
    updateUserRoles: "Update User Roles",
    role: "Role",
    remove: "Remove",
    userInvitation: "User invitation was successfully sent",
    userInvitationError: "Error sending user invitation.",
    userReinvitation: "Successfully reinvited user to account.",
    userReinvitationError: "Failed to reinvite user to account.",
    success: "Success",
    userDeleted: "Successfully deleted user from account.",
    userDeletedError:
      "An error occured whilst trying to delete the user from the account. You may lack the necessary permissions.",
    accountCreated: "Account has been successfully created.",
    accountCreatedError: "An error occured creating a new account.",
    consumableCreated: "has been successfully created",
    consumableCreatedError:
      "Something went wrong whilst creating the consumable",
    consumableDeleted: "The selected consumables were successfully deleted.",
    consumableDeletedError:
      "An error occured whilst attempting to delete the selected consumables.",
    consumableUpdated: "The selected consumable was successfully updated.",
    consumableUpdatedError:
      "The modified consumable was not updated successfully.",
    cosnumableIncorrectInput:
      "The folllowing consumables failed due to incorrect input:",
    deviceUpdated: "The selected device was successfully updated.",
    deviceUpdatedError: "The modified device was not updated successfully.",
    deviceIncorrectInput:
      "The folllowing devices failed due to incorrect input:",
    assetsDeleted: "The selected assets were successfully deleted.",
    assetsDeletedError:
      "An error occured whilst attempting to delete the selected assets.",
    assetsUpdated: "The selected assets were successfully updated.",
    assetsUpdatedError: "The modified asset was not updated successfully",
    assetsIncorrectInput:
      "The folllowing assets failed due to incorrect input:",
    assetsCreatedError: "Something went wrong whilst creating the asset",
    pointsCreatedError: "Something went wrong whilst creating the point",
    pointsDeleted: "The selected points were successfully deleted.",
    pointsDeletedError:
      "An error occured whilst attempting to delete the selected points.",
    pointsUpdated: "The selected points were successfully updated.",
    pointsUpdatedError: "The modified point was not updated successfully.",
    pointsIncorrectInput:
      "The folllowing points failed due to incorrect input:",
    routeUpdated: "The route order was successfully updated.",
    routeUpdatedError: "Unable to updated the route order.",
    schedulesDeleted: "The selected schedules were successfully deleted.",
    schedulesDeletedError:
      "An error occured whilst attempting to delete the selected schedules.",
    errorOccured: "An Error has occured.",
    errorPermissions:
      "The server returned an error stating that you do not have the required permissions to access the resources for this page.",
    deleteItemLabel: "will be deleted! Are you sure?",
    selectOption: "Select Option",
    occurringAfter: "Occurring After...",
    notFound: "Not Found",
    fieldIsRequired: "Field is Required",
    volumeRequirementValidation:
      "Value must be -1, greater than or equal to 0 or set to 'Purge' or 'P'. Number after decimal place must be specified",
    createNewAccount: "Create a New Account",
    addAccount: "Add a new account to your account.",
    accountName: "Account Name:",
    createAccount: "Create Account",
    addUser: "Add User",
    addNewUser: "Add a new user to your account.",
    userId: "User ID:",
    siteId: "Account ID:",
    roleId: "Role ID",
    inviteUser: "Invite User",
    currentFeed: "Your current feed",
    selectAccountId: "An account id must be selected for a device.",
    emailRequired: "Email required",
    invalidEmail: "Invalid Email",
    roleRequired: "Role Required",
    noSpecialCharacters: "Cannot contain any special characters.",
    locationCreatedError: "Something went wrong whilst creating the location",
    locationDeleted: "The selected locations were successfully deleted.",
    locationDeletedError:
      "An error occured whilst attempting to delete the selected locations.",
    locationUpdated: "All modified locations were successfully updated!",
    locationUpdatedError: "The modified location was not updated successfully",
    locationIncorrectInput:
      "The folllowing locations failed due to incorrect input:",
    invalidAction: "Invalid action",
    invalidStatusResult: "Invalid status result",
    noRedirect: "Should not redirect.",
    invalidAuthentication: "Invalid authentication result status",
    deviceCreated: "The device was successfully created",
    deviceCreatedError: "Something went wrong whilst creating the device",
    deviceDeleted: "The specified devices were successfully deleted",
    deviceDeletedError: "Something went wrong whilst deleting the devices",
    sunday: "Su",
    monday: "M",
    tuesday: "T",
    wednesday: "W",
    thursday: "Th",
    friday: "F",
    saturday: "S",
    true: "TRUE",
    false: "FALSE",
    superAdmin: "SuperAdmin",
    accountAdmin: "AccountAdmin",
    manager: "Manager",
    generalUser: "GeneralUser",
    onboardApp: "Onboard App",
    goToLink: "Open link",
    copyLink: "Copy link address",
    reports: "Reports",
    complianceReport: "Compliance Report",
    selectAll: "Select All",
    points: "Points",
    generateReport: "Generate Report",
    clearFilters: "Clear Filters",
    selected: "Selected",
    loading: "Loading...",
    pendingActions: "Pending Actions Report",
    scheduleEmail: "Schedule Email",
    emailScheduleText: "Schedule email delivery for this report.",
    from: "From",
    to: "To",
    subject: "Subject",
    period: "Period",
    time: "Time",
    endpointReport: "Endpoints Report",
    setFiltersText: "Set the filters to generate the report.",
    endpoints: "Endpoints",
    uploaded: "Uploaded",
    detailTreeList: "Detail TreeList",
    detailView: "Detail View",
    manualSystem: "Manual System",
    automaticSystem: "Automatic System",
    site: "Site",
    expectedConsumable: "Expected Consumable",
    volumeDelivered: "Vol. Delivered",
    dueDate: "Due Date",
    expiryDate: "Expiry Date",
    compliant: "Compliant",
    volumeRequirement: "Vol. Requirement",
    volumeTolerance: "Vol. Tolerance",
    status: "Status",
    viewActionsText: "View actions within your GreaseCloud network",
    showAllActionsFrom: "Show all actions From",
    pending: "Pending",
    closed: "Closed",
    endpointCreated: "The endpoint was successfully created",
    endpointCreatedError: "Something went wrong whilst creating the endpoint",
    endpointDeleted: "The specified endpoints were successfully deleted",
    endpointDeletedError: "Something went wrong whilst deleting the endpoints",
    endpointUpdated: "The selected endpoint was successfully updated.",
    endpointUpdatedError: "The modified endpoint was not updated successfully.",
    endpointIncorrectInput:
      "The folllowing endpoints failed due to incorrect input:",
    manageEndpoints: "Manage Endpoints",
    manageendpointsText:
      "Create identities for new endpoints, update their accounts, and monitor usage.",
    endpointsText:
      "Represents Greaseboss endpoints registered within your account.",
    hardwareVersion: "Hardware Version",
    schedulePattern: "Schedule Pattern",
    notes: "Notes",
    temperatureInC: "Temperature (°C)",
    temperature: "Temperature",
    voltage: "Voltage (V)",
    rssiDbm: "RSSI from Gateway (dBm)",
    endpointRssi: "RSSI from Endpoint (dBm)",
    cumulative: "Cumulative (cc)",
    list: "List",
    noSchedule: "No Schedule",
    pointActivity: "Point Activity",
    endpointActivity: "Endpoint Activity",
    activity: "Activity",
    metrics: "Metrics",
    selectPointEndpoint: "Select an Asset or a Point/Endpoint.",
    noDataFound: "No Data Found",
    changeDateRangeAndPoint:
      "Change the Date Range or select another Point/Endpoint.",
    diagnostics: "Diagnostics",
    thisCouldBeBecause: "This could be because",
    trySelectingDates:
      "The endpoint has not posted diagnostics in a while.  Try selecting different dates.",
    unableTocommunicate:
      "The endpoint is unable to communicate with the GreaseCloud. Please contact support.",
    siteExplorer: "Site Explorer",
    endpointMapping: "Endpoint Mapping",
    tagMapping: "Tag Mapping",
    sessionExpired: "Your session has expired, you will now be logged out.",
    none: "None",
    aggregator: "Aggregator",
    complianceSent: "Compliance Report successfully sent.",
    unableToSendEmail: "Unable to send report by email.",
    sendEmail: "Send Email",
    send: "Send",
    sendEmailText: "Send email for this report.",
    reportDate: "Report Date",
    processing: "Processing...",
    last: "Last",
    days: "Days",
    today: "Today",
    yesterday: "Yesterday",
    reservoirs: "Reservoirs",
    reservoirSuccessfully: "reservoir has been successfully",
    reservoirDeleted: "The selected reservoir was successfully deleted.",
    reservoirDeletedError:
      "An error occured whilst attempting to delete the selected reservoir.",
    addReservoir: "Add Reservoir",
    updateReservoir: "Update Reservoir",
    deleteReservoir: "Delete Reservoir",
    linkedPoints: "Linked Endpoints",
    addNote: "Add or edit a note:",
    reservoirText:
      "Reservoirs can be used to monitor the volume level of grease auto-lubricators, buckets, barrels and bins.",
    deviceNotConnected: "Device has not connected to GreaseCloud.",
    lastSeen: "Last Seen",
    never: "Never",
    requirement: "Requirement",
    requirements: "Requirements",
    requirementsText:
      "Requirements define the volume of grease required to be dispensed by an endpoint.",
    createRequirements: "Create Requirements",
    requirementName: "Requirement Name",
    rightAmount: "Right Amount",
    rightTime: "Right Time",
    rightGrease: "Right Grease",
    next: "Next",
    autoThreshold: "Auto-threshold",
    on: "On",
    off: "Off",
    "Over-greased": "Over-greased",
    "Under-greased": "Under-greased",
    submit: "Submit",
    autoRange: "Auto-range",
    thresholds: "Thresholds",
    barChart: "Bar Chart",
    lineChart: "Line Chart",
    capacity: "Capacity",
    estimatedVolume: "Estimated Volume",
    averageDailyConsumption: "Average Daily Consumption",
    projectedDepletionDate: "Projected Depletion Date",
    lastReset: "Last Reset",
    available: "Available",
    consumed: "Consumed",
    lastResetLevel: "Last Reset Level",
    verifyLastResetDate: "past empty. Verify reservoir last reset date",
    viewReservoir: "View Reservoir",
    noteReservoir:
      "Note: All calculations are performed using cc, where 1 gram = 1 cc.",
    outlets: "Outlets",
    inlets: "Inlets",
    date: "Date",
    resetLevel: "Reset Level",
    resetBy: "Reset By(Date)",
    comments: "Comments",
    resetDate: "Reset Date",
    resetReservoir: "Reset Reservoir",
    override: "Override",
    reset: "Reset",
    averageDailyConsumptionTooltip:
      "The average daily consumption is computed based on the last",
    daysOfActivity: "days of activity.",
    requirementSuccessfully: "requirement has been successfully",
    requirementDeleted: "The selected requirement was successfully deleted.",
    requirementDeletedError:
      "An error occured whilst attempting to delete the selected requirement.",
    updateRequirements: "Update Requirements",
    requirementSummary: "Requirement Summary",
    linkReservoir: "Link Reservoir",
    noReservoirLinked: "No Reservoir has been linked.",
    addLinkReservoir: "Add and Link reservoir",
    unplannedVolumeDispensed: "Unplanned Volume Dispensed",
    noRequirementAssigned: "No Requirement Assigned",
    pleaseAssignRequirement:
      "Please assign a requirement to view the compliance.",
    reservoirsReport: "Reservoirs Report",
    manageAuthTokens: "Manage Auth Tokens",
    noRequirementWithinTime: "No requirement within time period",
    noRequirement: "No Requirement",
    estimated: "Estimated",
    depletionDate: "Depletion Date",
    average: "Average",
    perDay: "per day",
    linked: "Linked",
    futureReservoirRequirements: "Future Reservoir Requirements",
    currentReservoirUsage: "Current Reservoir Usage",
    currentMonth: "Current Month",
    nextMonth: "Next Month",
    nextNextMonth: "2 Months",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    alerts: "Alerts",
    alert: "Alert",
    assignedTo: "Assigned To",
    criticality: "Criticality",
    type: "Type",
    enabled: "Enabled",
    deleteAlert: "Delete Alert",
    createAlert: "Create Alert",
    hours: "Hours",
    weeks: "Weeks",
    months: "Months",
    successfullyUpdated: "successfully updated",
    successfullyCreated: "successfully created",
    low: "Low",
    medium: "Medium",
    high: "High",
    critical: "Critical",
    assignTo: "Assign To",
    condition: "Condition",
    field: "Field",
    value: "Value",
    operator: "Operator",
    unit: "Unit",
    notifications: "Notifications",
    notifyUsers: "Notify the following users",
    emails: "Emails",
    message: "Message",
    updateAlert: "Update Alert",
    showAllNotifications: "Show all notifications",
    result: "Result",
    emailSent: "Email Sent",
    acknowledged: "Acknowledged",
    lastEvaluated: "Last Evaluated",
    lastNotified: "Last Notified",
    lastTriggered: "Last Triggered",
    triggeredBy: "Triggered By",
    noDataDepletion: "No data - Minimum 3 days required to calculate depletion",
    equalTo: "Equal to",
    notEqualTo: "Not equal to",
    greaterThan: "Greater than",
    greaterThanOrEqualTo: "Greater than or equal to",
    lessThan: "Less than",
    lessThanOrEqualTo: "Less than or equal to",
    alertSuccessfullydeleted: "The selected alert was successfully deleted.",
    errorDeletingAlert: "An error occured whilst attempting to delete the selected alert.",
    flowEvents: "Flow Events",
    pressureEvents: "Pressure Events",
    singleOrMultipleChart: "Single or Multiple Chart",
    pressureMean: "Pressure Mean",
    preassureMinMax: "Pressure Min/Max",
    pressureTemperature: "Pressure Temperature",
    max: "Max",
    min: "Min",
    mean: "Mean",
    pressureMin: "Pressure Min",
    pressureMax: "Pressure Max",
    pressure: "Pressure",
    flow: "Flow",
    diagnostic: "Diagnostic",
    heartbeat: "Heartbeat",
    endpointsEvents: "Endpoints Events",
    pointsEvents: "Points Events",
    resetHistory: "Reset History",
    triggered: "Triggered",
    exportToExcel: "Download to Excel",
    reservoir: "Reservoir",
    endpoint: "Endpoint",
    noTagsAssigned: "There are no Grease Tags assigned to this Account.",
    uploadCsv: "Upload CSV file",
    uploadEndpoints: "Upload Endpoints",
    uploadCSVText: "Upload CSV file:",
    online: "Online",
    offline: "Offline",
    offlinePeriod: "Offline Period",
    eventType: "Event Type",
    comment: "Comment",
    reasonCode: "Reason Code",
    addOnlineOffline: "Add Online / Offline Event",
    manageOperationalState: "Manage Operational State",
    maintenancePlanned: "Maintenance (Planned)",
    maintenanceUnplanned: "Maintenance (Unplanned)",
    operationalShutdownPlanned: "Operational Shutdown (Planned)",
    operationalShutdownUnplanned: "Operational Shutdown (Unplanned)",
    other: "Other",
    dashboard: "Dashboard",
    automaticCompliance: "Automatic Compliance",
    manualCompliance: "Manual Compliance",
    inLast7Days: "in the last 7 days",
    noDataAvailable: "No Data Available.",
    seeAll: "See All",
    withoutRequirement: "Without Requirement",
    tags: "Tags",
    unmapped: "Unmapped",
    withoutSchedule: "Without Schedule",
    nonCompliant: "Non-Compliant",
    settingsNotConfigured: "Settings are not configured for this account.",
    contactSupport: "Please contact support using the link below:",
    noEndpointDeviceAssigned: "No endpoint device assigned",
    noEventsToDetermine: "No events to determine last seen",
    minFlowRate: "Min Flow Rate (L/min)",
    avgFlowRate: "Average Flow Rate (L/min)",
    maxFlowRate: "Max Flow Rate (L/min)",
    windowSize: "Window Size (s)",
    samplePeriod: "Sample Period (s)",
    volumeInL: "Volume (L)",
    minMaxFlowRate: "Min/Max Flow Rate (L/min)",
    flowRate: "Flow Rate",
    copyToken: "Please copy and store this token. You will not be able to see it again.",
    tokenCreated: "Auth Token Created",
    latitude: "Latitude",
    longitude: "Longitude",
    altitude: "Altitude",
    accuracy: "Accuracy",
    speed: "Speed",
    speedAccuracy: "Speed Accuracy",
    heading: "Heading",
    gps: "GPS",
    gpsEvents: "GPS Events",
    tasks: "Tasks",
    viewTasksText: "View tasks within your GreaseCloud network",
    showAllTasksFrom: "Show all tasks From",
    pendingTasks: "Pending Tasks Report",
  },

  grid: {
    groupPanelEmpty:
      "Drag a column header and drop it here to group by that column",
    pagerItemsPerPage: "items per page",
    pagerInfo: "{0} - {1} of {2} items",
    pagerFirstPage: "Go to the first page",
    pagerPreviousPage: "Go to the previous page",
    pagerNextPage: "Go to the next page",
    pagerLastPage: "Go to the last page",
    pagerPage: "Page",
    pagerOf: "of",
    pagerTotalPages: "{0}",
    filterClearButton: "Clear",
    filterEqOperator: "Is equal to",
    filterNotEqOperator: "Is not equal to",
    filterIsNullOperator: "Is null",
    filterIsNotNullOperator: "Is not null",
    filterIsEmptyOperator: "Is empty",
    filterIsNotEmptyOperator: "Is not empty",
    filterStartsWithOperator: "Starts with",
    filterContainsOperator: "Contains",
    filterNotContainsOperator: "Does not contain",
    filterEndsWithOperator: "Ends with",
    filterGteOperator: "Is greater than or equal to",
    filterGtOperator: "Is greater than",
    filterLteOperator: "Is less than or equal to",
    filterLtOperator: "Is less than",
    filterIsTrue: "Is true",
    filterIsFalse: "Is false",
    filterBooleanAll: "(All)",
    filterAfterOrEqualOperator: "Is after or equal to",
    filterAfterOperator: "Is after",
    filterBeforeOperator: "Is before",
    filterBeforeOrEqualOperator: "Is before or equal to",
    noRecords: "No records available.",
    filterAriaLabel: "Filter",
    filterCheckAll: "Check All",
    filterChooseOperator: "Choose Operator",
    filterSelectedItems: "selected items",
    filterSubmitButton: "Filter",
    filterTitle: "Filter",
    filterAndLogic: "And",
    filterOrLogic: "Or",
    groupColumn: "Group Column",
    searchPlaceholder: "Search",
    sortAriaLabel: "Sortable",
    sortAscending: "Sort Ascending",
    sortDescending: "Sort Descending",
    ungroupColumn: "Ungroup Column",
  },
  filter: {
    eqOperator: "Is equal to",
    notEqOperator: "Is not equal to",
    isNullOperator: "Is null",
    isNotNullOperator: "Is not null",
    isEmptyOperator: "Is empty",
    isNotEmptyOperator: "Is not empty",
    startsWithOperator: "Starts with",
    containsOperator: "Contains",
    notContainsOperator: "Does not contain",
    endsWithOperator: "Ends with",
    gteOperator: "Is greater than or equal to",
    gtOperator: "Is greater than",
    lteOperator: "Is less than or equal to",
    ltOperator: "Is less than",
    isTrue: "Is true",
    isFalse: "Is false",
    afterOrEqualOperator: "Is after or equal to",
    afterOperator: "Is after",
    beforeOperator: "Is before",
    beforeOrEqualOperator: "Is before or equal to",
    andLogic: "And",
    orLogic: "Or",
    addExpression: "Add Expression",
    addGroup: "Add Group",
    close: "Close",
    filterAriaLabel: "Filter",
  },
  dateinput: {
    increment: "Increase value",
    decrement: "Decrease value",
  },
  calendar: {
    today: "TODAY",
  },
  colorPicker: {
    dropdownButtonAriaLabel: "Select",
  },
  datepicker: {
    toggleCalendar: "Toggle calendar",
  },
  daterangepicker: {
    swapStartEnd: "Swap start and end values",
    start: "Start",
    end: "End",
    separator: " ",
  },
  datetimepicker: {
    date: "Date",
    time: "Time",
    toggleDateTimeSelector: "Toggle date-time selector",
    cancel: "Cancel",
    set: "Set",
  },
  editor: {
    addColumnAfter: "Add column on the right",
    addColumnBefore: "Add column on the left",
    addRowAfter: "Add row below",
    addRowBefore: "Add row above",
    alignCenter: "Center text",
    alignJustify: "Justify",
    alignLeft: "Align text left",
    alignRight: "Align text right",
    backgroundColor: "Background color",
    bold: "Bold",
    bulletList: "Insert unordered list",
    cleanFormatting: "Clean formatting",
    createTable: "Create a table",
    deleteColumn: "Delete column",
    deleteRow: "Delete row",
    deleteTable: "Delete table",
    "findReplace-dialog-title": "Find and Replace",
    "findReplace-findWhat": "Find What:",
    "findReplace-matchCase": "Match Case",
    "findReplace-matchCyclic": "Match cyclic (Wrap around)",
    "findReplace-matchWord": "Match whole word only",
    "findReplace-matches": "{0} of {1} matches",
    "findReplace-nextMatch": "Next",
    "findReplace-prevMatch": "Prev",
    "findReplace-replace": "Replace",
    "findReplace-replaceAll": "Replace All",
    "findReplace-replaceWith": "Replace With",
    "findReplace-tab-find": "Find",
    "findReplace-tab-replace": "Replace",
    "findReplace-tool-title": "Find and Replace",
    "findReplace-useRegExp": "Regular Expression",
    fontName: "Font Name",
    fontSize: "Font Size",
    foregroundColor: "Color",
    format: "Format",
    hyperlink: "Insert hyperlink",
    "hyperlink-dialog-cancel": "Cancel",
    "hyperlink-dialog-content-address": "Web address",
    "hyperlink-dialog-content-newwindow": "Open link in new window",
    "hyperlink-dialog-content-title": "Title",
    "hyperlink-dialog-insert": "Insert",
    "hyperlink-dialog-title": "Insert hyperlink",
    image: "Insert image",
    "image-address": "Web address",
    "image-altText": "Alternate text",
    "image-cancel": "Cancel",
    "image-dialog-title": "Insert image",
    "image-height": "Height (px)",
    "image-insert": "Insert",
    "image-title": "Title",
    "image-width": "Width (px)",
    indent: "Indent",
    insertFile: "Insert file",
    insertTableHint: "Create a {0} x {1} table",
    "insertfile-dialog-cancel": "Cancel",
    "insertfile-dialog-content-address": "Web address",
    "insertfile-dialog-content-title": "Title",
    "insertfile-dialog-insert": "Insert",
    "insertfile-dialog-title": "Insert file",
    italic: "Italic",
    mergeCells: "Merge cells",
    orderedList: "Insert ordered list",
    outdent: "Outdent",
    pdf: "Export as PDF",
    print: "Print",
    redo: "Redo",
    selectAll: "Select All",
    splitCell: "Split cell",
    strikethrough: "Strikethrough",
    subscript: "Subscript",
    superscript: "Superscript",
    underline: "Underline",
    undo: "Undo",
    unlink: "Remove hyperlink",
    viewHtml: "View HTML",
    "viewHtml-cancel": "Cancel",
    "viewHtml-dialog-title": "View HTML",
    "viewHtml-update": "Update",
  },
  flatColorPicker: {
    cancelBtn: "Cancel",
    applyBtn: "Apply",
  },
  timepicker: {
    now: "NOW",
    selectNow: "Select Now",
    cancel: "Cancel",
    set: "Set",
    toggleTimeSelector: "Toggle TimeSelector",
    toggleClock: "Toggle Clock",
  },
  dropdowns: {
    nodata: "NO DATA FOUND.",
    clear: "Clear",
  },
  numerictextbox: {
    increment: "Increase value",
    decrement: "Decrease value",
  },
  upload: {
    cancel: "Cancel",
    clearSelectedFiles: "Clear",
    defaultDropZoneNote: "",
    dropFilesHere: "Drop file here to upload",
    dropZoneHint: "Drag and drop files here to upload.",
    dropZoneNote: "Only {0} files are allowed.",
    files: "files",
    headerStatusUploaded: "Done",
    headerStatusUploading: "Processing...",
    invalidFileExtension: "File type not allowed.",
    invalidFiles: "Invalid file(s). Please check file upload requirements.",
    invalidMaxFileSize: "File size too large.",
    invalidMinFileSize: "File size too small.",
    remove: "Remove",
    retry: "Retry",
    select: "Select file...",
    selectNoFilesTitle: "No files selected",
    selectTitle: "Press to select more files",
    statusUploadFailed: "File(s) failed to upload.",
    statusUploaded: "File(s) successfully uploaded.",
    total: "Total",
    uploadSelectedFiles: "Upload",
  },
  sortable: {
    noData: "No Data",
  },
  pager: {
    itemsPerPage: "items per page",
    info: "{0} - {1} of {2} items",
    firstPage: "Go to the first page",
    previousPage: "Go to the previous page",
    nextPage: "Go to the next page",
    lastPage: "Go to the last page",
    page: "Page",
    of: "of",
    pagerTotalPages: "{0}",
  },
  treelist: {
    filterClearButton: "Clear",
    filterEqOperator: "Is equal to",
    filterNotEqOperator: "Is not equal to",
    filterIsNullOperator: "Is null",
    filterIsNotNullOperator: "Is not null",
    filterIsEmptyOperator: "Is empty",
    filterIsNotEmptyOperator: "Is not empty",
    filterStartsWithOperator: "Starts with",
    filterContainsOperator: "Contains",
    filterNotContainsOperator: "Does not contain",
    filterEndsWithOperator: "Ends with",
    filterGteOperator: "Is greater than or equal to",
    filterGtOperator: "Is greater than",
    filterLteOperator: "Is less than or equal to",
    filterLtOperator: "Is less than",
    filterIsTrue: "Is true",
    filterIsFalse: "Is false",
    filterBooleanAll: "(All)",
    filterAfterOrEqualOperator: "Is after or equal to",
    filterAfterOperator: "Is after",
    filterBeforeOperator: "Is before",
    filterBeforeOrEqualOperator: "Is before or equal to",
    noRecords: "No records available",
  },
  scheduler: {
    editorValidationRequired: "Field is required.",
    editorValidationStart: "Start time must be before End time.",
    editorValidationEnd: "End time must be after Start time.",
    allEvents: "All events",
    allDay: "all day",
    today: "Today",
    dayViewTitle: "Day",
    monthViewTitle: "Month",
    weekViewTitle: "Week",
    workWeekViewTitle: "Work Week",
    timelineViewTitle: "Timeline",
    agendaViewTitle: "Agenda",
    deleteTitle: "Delete",
    previousTitle: "Previous",
    nextTitle: "Next",
    showFullDay: "Show full day",
    showWorkDay: "Show business hours",
    editorOccurrence: "Edit current occurrence",
    editorSeries: "Edit the series",
    editorRecurringConfirmation:
      "Do you want to edit only this event occurrence or the whole series?",
    editorRecurringDialogTitle: "Edit Recurring Item",
    editorSave: "Save",
    editorCancel: "Cancel",
    editorDelete: "Delete",
    editorTitle: "Event",
    editorEventTitle: "Title",
    editorEventStart: "Start",
    editorEventStartTimeZone: "Start Time Zone",
    editorEventEnd: "End",
    editorEventEndTimeZone: "End Time Zone",
    editorEventAllDay: "All Day Event",
    editorEventDescription: "Description",
    editorEventSeparateTimeZones: "End in different Time Zone",
    editorEventTimeZone: "Specify Time Zone",
    recurrenceEditorRepeat: "Repeat",
    recurrenceEditorDailyInterval: "day(s)",
    recurrenceEditorDailyRepeatEvery: "Repeat every",
    recurrenceEditorWeeklyInterval: "week(s)",
    recurrenceEditorWeeklyRepeatEvery: "Repeat every",
    recurrenceEditorWeeklyRepeatOn: "Repeat on",
    recurrenceEditorMonthlyDay: "Day",
    recurrenceEditorMonthlyInterval: "month(s)",
    recurrenceEditorMonthlyRepeatEvery: "Repeat every",
    recurrenceEditorMonthlyRepeatOn: "Repeat on",
    recurrenceEditorYearlyOf: "of",
    recurrenceEditorYearlyRepeatEvery: "Repeat every",
    recurrenceEditorYearlyRepeatOn: "Repeat on",
    recurrenceEditorYearlyInterval: "year(s)",
    recurrenceEditorFrequenciesDaily: "Daily",
    recurrenceEditorFrequenciesMonthly: "Monthly",
    recurrenceEditorFrequenciesNever: "Never",
    recurrenceEditorFrequenciesWeekly: "Weekly",
    recurrenceEditorFrequenciesYearly: "Yearly",
    recurrenceEditorOffsetPositionsFirst: "First",
    recurrenceEditorOffsetPositionsSecond: "Second",
    recurrenceEditorOffsetPositionsThird: "Third",
    recurrenceEditorOffsetPositionsFourth: "Fourth",
    recurrenceEditorOffsetPositionsLast: "Last",
    recurrenceEditorWeekdaysDay: "Day",
    recurrenceEditorWeekdaysWeekday: "Weekday",
    recurrenceEditorWeekdaysWeekendday: "Weekend Day",
    recurrenceEditorEndAfter: "After",
    recurrenceEditorEndOccurrence: "occurrence(s)",
    recurrenceEditorEndLabel: "End",
    recurrenceEditorEndNever: "Never",
    recurrenceEditorEndOn: "On",
    deleteConfirmation: "Are you sure you want to delete this event?",
    deleteRecurringConfirmation:
      "Do you want to delete only this event occurrence or the whole series?",
    deleteOccurrence: "Delete current occurrence",
    deleteSeries: "Delete the series",
    deleteDialogTitle: "Delete Event",
    deleteRecurringDialogTitle: "Delete Recurring Item",
    dateTitle: "Date",
    timeTitle: "Time",
    eventTitle: "Event",
    noEvents: "no events",
  },
  listbox: {
    moveUp: "Move Up",
    moveDown: "Move Down",
    transferTo: "Transfer To",
    transferFrom: "Transfer From",
    transferAllTo: "Transfer All To",
    transferAllFrom: "Transfer All From",
    remove: "Delete",
  },
  stepper: {
    optionalText: "(Optional)",
  },
  taskBoard: {
    addCardButton: "Add card",
    addCardCreateButton: "Create",
    addCardDescriptionLabel: "Description",
    addCardPriorityLabel: "Priority",
    addCardTitle: "Create new card",
    addCardTitleLabel: "Title",
    deleteCardButton: "Delete card",
    deleteColumnButton: "Delete column",
    deleteColumnCancelButton: "Cancel",
    deleteColumnConfirmButton: "Delete",
    deleteColumnDialogMessage: "Are you sure you want to delete this column?",
    deleteColumnDialogTitle: "Delete column?",
    deleteTaskCancelButton: "Cancel",
    deleteTaskConfirmButton: "Delete",
    deleteTaskDialogMessage: "Are you sure you want to delete this card?",
    deleteTaskDialogTitle: "Delete Card",
    editCardButton: "Edit card",
    editCardPaneSaveButton: "Save changes",
    editCardPaneTitle: "Edit ",
    editColumnButton: "Edit column",
    editPaneCancelButton: "Cancel",
    editPaneDescriptionLabel: "Description:",
    editPanePriorityLabel: "Priority:",
    editPaneTitleLabel: "Title:",
    previewPaneDeleteButton: "Delete",
    previewPaneEditButton: "Edit",
    previewPanePriorityLabel: "Priority:",
    toolbarAddColumnButton: "Add column",
    toolbarSearchField: "Search",
  },
  labels: {
    labelsOptional: "(Optional)",
  },
  slider: {
    increment: "Increase",
    decrement: "Decrease",
    dragTitle: "Drag",
  },
  colorGradient: {
    r: "r",
    g: "g",
    b: "b",
    a: "a",
    hex: "hex",
    contrastRatio: "Contrast ratio",
    colorGradientAALevel: "AA",
    colorGradientAAALevel: "AAA",
    colorGradientPass: "Pass",
    colorGradientFail: "Fail",
  },
  checkbox: {
    validation: "Please check this box if you want to proceed!",
    optionalText: "(Optional)",
  },
  radioButton: {
    validation: "Please select option if you want to proceed!",
  },
  switch: {
    validation: "Please turn on if you want to proceed!",
  },
  gantt: {
    addAbove: "Add Above",
    addBelow: "Add Below",
    addChild: "Add Child",
    addTask: "Add Task",
    dayViewTitle: "Day",
    deleteConfirmation: "Are you sure you want to delete this event?",
    deleteDialogTitle: "Delete Event",
    editCancel: "Cancel",
    editSave: "Save",
    editorDelete: "Delete",
    editorTaskComplete: "Complete",
    editorTaskEnd: "End",
    editorTaskStart: "Start",
    editorTaskTitle: "Title",
    editorTitle: "Task",
    editorValidationEnd: "End time must be after Start time.",
    editorValidationRequired: "Field is required.",
    editorValidationStart: "Start time must be be before End time.",
    filterAfterOperator: "Is after",
    filterAfterOrEqualOperator: "Is after or equal to",
    filterBeforeOperator: "Is before",
    filterBeforeOrEqualOperator: "Is before or equal to",
    filterBooleanAll: "(All)",
    filterClearButton: "Clear",
    filterContainsOperator: "Contains",
    filterEndsWithOperator: "Ends with",
    filterEqOperator: "Is equal to",
    filterGtOperator: "Is greater than",
    filterGteOperator: "Is greater than or equal to",
    filterIsEmptyOperator: "Is empty",
    filterIsFalse: "Is false",
    filterIsNotEmptyOperator: "Is not empty",
    filterIsNotNullOperator: "Is not null",
    filterIsNullOperator: "Is null",
    filterIsTrue: "Is true",
    filterLtOperator: "Is less than",
    filterLteOperator: "Is less than or equal to",
    filterNotContainsOperator: "Does not contain",
    filterNotEqOperator: "Is not equal to",
    filterStartsWithOperator: "Starts with",
    monthViewTitle: "Month",
    noRecords: "No records available",
    weekViewTitle: "Week",
    yearViewTitle: "Year",
  },
  multiviewcalendar: {
    prevView: "Navigate to previous view",
    nextView: "Navigate to next view",
  },
  columnMenu: {
    columnMenuFilterClearButton: "Clear",
    columnMenuFilterSubmitButton: "Filter",
    columnMenuFilterTitle: "Filter",
    columnMenuSortAscending: "Sort Ascending",
    columnMenuSortDescending: "Sort Descending",
    columnMenuFilterEqOperator: "Is equal to",
    columnMenuFilterNotEqOperator: "Is not equal to",
    columnMenuFilterIsNullOperator: "Is null",
    columnMenuFilterIsNotNullOperator: "Is not null",
    columnMenuFilterIsEmptyOperator: "Is empty",
    columnMenuFilterIsNotEmptyOperator: "Is not empty",
    columnMenuFilterStartsWithOperator: "Starts with",
    columnMenuFilterContainsOperator: "Contains",
    columnMenuFilterNotContainsOperator: "Does not contain",
    columnMenuFilterEndsWithOperator: "Ends with",
    columnMenuFilterGteOperator: "Is greater than or equal to",
    columnMenuFilterGtOperator: "Is greater than",
    columnMenuFilterLteOperator: "Is less than or equal to",
    columnMenuFilterLtOperator: "Is less than",
    columnMenuFilterIsTrue: "Is true",
    columnMenuFilterAfterOrEqualOperator: "Is after or equal to",
    columnMenuFilterAfterOperator: "Is after",
    columnMenuFilterBeforeOperator: "Is before",
    columnMenuFilterBeforeOrEqualOperator: "Is before or equal to",
    columnMenuFilterAndLogic: "And",
    columnMenuFilterOrLogic: "Or",
  },
  pivotgrid: {
    fieldMenuReset: "Reset",
    fieldMenuApply: "Apply",
    configuratorCancel: "Cancel",
    configuratorApply: "Apply",
    configuratorTitle: "Settings",
    configuratorEditorSearchTitle: "Fields",
    configuratorEditorSearchPlaceholder: "Search...",
    configuratorColumnsTitle: "Columns",
    configuratorRowsTitle: "Rows",
    configuratorMeasuresTitle: "Values",
    configuratorButtonLabel: "Change settings",
    configuratorMissingFields: "Select some fields to begin setup",
  },
};
