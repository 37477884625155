import { ReactElement, useEffect, useMemo, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import treeListApiService from "../../../Utilities/TreeListApiService";
import { EventFilter } from "../../../../Events/Utilities/EventsApiService";
import { useQuery } from "react-query";
import LoadingSpinner from "../../../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../../../Shared/Components/Cards/ErrorCard";
import NoDataPageTab from "../../NoDataPageTab";
import moment from "moment";
import { enMessages } from "../../../../../messages/en-US";
import EndpointPressureEventsDto from "../../../../../Models/Endpoint/EndpointPressureEventsDto";
import EndpointDiagnoticsTabPageForTables from "./EndpointDiagnosticsTabPageForTables";
import EndpointStatusDiagnostics from "./EndpointStatusDiagnostics";
import { ChartToolbar } from "../../../Utilities/ChartToolbar";
import useChartStore from "../../../../../Zustand/chartStore";

interface Props {
  eventFilter: EventFilter
  selectedItem: string
  selectedEndpointId: string;
  endpointFeature: any;
}

export default function EndpointDiagnosticsTabPage(props: Props): ReactElement {
  const localizationService = useLocalization();

  const aggregator = useChartStore((state) => state.aggregator);

  const endpointsDiagnosticsQuery = useQuery<any>(["Diagnostics", props.eventFilter, props.selectedItem], async () => {
    return treeListApiService.getAllEndpointsDiagnosticsChart(props.eventFilter, props.selectedItem);
  });

  const endpointsQuery = useQuery<any>(['endpointChart', props.selectedEndpointId, props.eventFilter, aggregator], async () => {
    return  treeListApiService.getAllEndpointsEventsFlowChart(props.eventFilter, props.selectedEndpointId, aggregator) 
  });

  const diagnosticsChart = useMemo(() => {
    return endpointsDiagnosticsQuery.data;
  }, [endpointsDiagnosticsQuery.data]);
  diagnosticsChart?.sort((a,b) => a.timestamp > b.timestamp ? 1 : -1);

  const endpointsMetricsQuery = useQuery<any>(["Metrics", props.eventFilter, props.selectedItem], async () => {
    return treeListApiService.getAllEndpointsMetricsChart(props.eventFilter, props.selectedItem);
  });
  const metricsChart = useMemo(() => {
    return endpointsMetricsQuery.data;
  }, [endpointsMetricsQuery.data]);
  metricsChart?.sort((a,b) => a.timestamp > b.timestamp ? 1 : -1);

  const notificationsQuery = useQuery<any>("notificationsStatus", async () => {
    return treeListApiService.getEndpointNotifications(props.selectedEndpointId, props.eventFilter, 1);
  });

  const statusQuery = useQuery<any>("statusChart", async () => {
    return treeListApiService.getEndpointStatus(props.selectedEndpointId, props.eventFilter, 0);
  });

  useEffect(() => {
    statusQuery.refetch();
  }, [props.eventFilter]);

  useEffect(() => {
    endpointsDiagnosticsQuery.refetch();
    endpointsMetricsQuery.refetch();
    endpointsQuery.refetch();
  }, [props.selectedItem, props.eventFilter])

  const endpointPressureEventsChartQuery = useQuery<EndpointPressureEventsDto[]>([`endpointPressureEventsChartQuery`, props.selectedEndpointId, props.eventFilter, aggregator], async () => {
    return  treeListApiService.getEndpointPressureEventsChart(props.selectedEndpointId, props.eventFilter, aggregator) 
  }, {enabled: props.endpointFeature.isPressureSupported});

  const diagnosticsDataChart = useMemo(() => {
    const temperature = diagnosticsChart?.map(x => x.temperature)
    const voltage = diagnosticsChart?.map(x => x.voltage/1000)
    const rssi = metricsChart?.map(x => x.rssi)
    const endpointRssi = metricsChart?.map(x => x.endpointRssi)
    const cumulative = metricsChart?.map(x => x.kFactor * x.cumulative )

    return {
      statusChart: {
        dateFilter: props.eventFilter, timestamps: endpointsQuery.data?.timestamps, alerts: notificationsQuery.data, statusData: statusQuery.data, },

      diagnosticsChart: {
        timestampsDiagnostics: diagnosticsChart?.map(x => Math.floor(moment(x.timestamp).valueOf()/1000)),
        temperatureData: temperature?.map(item => item === 'NaN' ? NaN :  item) !== undefined ? temperature?.map(item => item === 'NaN' ? NaN :  item) :[],
        voltageData:  voltage?.map(item => item === 'NaN' ? NaN :  item) !== undefined ? voltage?.map(item => item === 'NaN' ? NaN :  item) : [],
        timestampsMetrics: metricsChart?.map(x => Math.floor(moment(x.timestamp).valueOf()/1000)),
        rssiData:  rssi?.map(item => item === 'NaN' ? NaN :  item) !== undefined ? rssi?.map(item => item === 'NaN' ? NaN :  item) : [],
        endpointRssiData:  endpointRssi?.map(item => item === 'NaN' ? NaN :  item) !== undefined ? endpointRssi?.map(item => item === 'NaN' ? NaN :  item) : [],
        cumulativeData:  cumulative?.map(item => item === 'NaN' ? NaN :  item) !==undefined ? cumulative?.map(item => item === 'NaN' ? NaN :  item) : [],
        dateFilter: props.eventFilter,
        timestamps: endpointsQuery.data?.timestamps,
        volumeDispensedData: endpointsQuery.data?.volumeDispensedData,
        strokeThickness: 1,
        aggregator: aggregator,
        pressureEvents: endpointPressureEventsChartQuery.data!,
      },    
    };
  },[endpointsDiagnosticsQuery.data, endpointsMetricsQuery.data, endpointsQuery.data, endpointPressureEventsChartQuery.data, statusQuery.data, notificationsQuery.data, aggregator]);

  if(props.selectedItem === "") {
    return <NoDataPageTab/>
  } else {
  if (endpointsDiagnosticsQuery.isLoading || endpointsMetricsQuery.isLoading || endpointsQuery.isLoading || endpointPressureEventsChartQuery.isLoading || notificationsQuery.isLoading || statusQuery.isLoading || endpointsDiagnosticsQuery.isFetching || endpointsMetricsQuery.isFetching || endpointsQuery.isFetching || endpointPressureEventsChartQuery.isFetching || notificationsQuery.isFetching || statusQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
    if (endpointsDiagnosticsQuery.error || endpointsMetricsQuery.error || endpointsQuery.error || endpointPressureEventsChartQuery.error || notificationsQuery.error || statusQuery.error) {
      return <ErrorCard />;
    } else { 
    return (
      <div>
      {diagnosticsChart.length !== 0 ? 
      <div>
        <ChartToolbar 
          title={localizationService.toLanguageString('custom.events', enMessages.custom.events)}
          lastSeen={null}
          showAutoRange={false} 
          showAggregatorOptions={true} 
          showChartOptions={false}
          showThresholds={false} 
          showSingleMultipleChart={false}
        />
        <EndpointStatusDiagnostics statusChartData={diagnosticsDataChart.statusChart} diagnosticsDataChart={diagnosticsDataChart.diagnosticsChart}/>
        
        <EndpointDiagnoticsTabPageForTables eventFilter={props.eventFilter} selectedItem={props.selectedEndpointId} aggregator={aggregator} endpointFeature={props.endpointFeature} diagnosticsData={endpointsDiagnosticsQuery.data!} metricsData={endpointsMetricsQuery.data!} endpointSerialNo={props.selectedItem}/>
      </div>
      :
      <NoDataPageTab/>
    } 
    </div>
    )
}}}}
